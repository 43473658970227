import React from 'react'
import Collapse from '../../../../../utils/Collapse'
import SortableTable from '../../../../../utils/SortableTable'

export default function ServiceHistory(props) {
    if (props.service?.history?.length == 0) return null

    return (
        <Collapse
            title={'History'}
            className={'h5'}
            style={{
                padding: '1rem',
            }}
        >
            <SortableTable
                headers={[
                    {
                        name: 'Date',
                        value: ['date'],
                        date: true,
                    },
                    {
                        name: 'Stage',
                        value: ['stage_name'],
                    },
                    {
                        name: 'Action',
                        value: ['action'],
                    },
                    {
                        name: 'Updated By',
                        value: ['updated_name'],
                    },
                    {
                        name: 'Assigned To',
                        value: ['assigned_name'],
                    },
                ]}
                info={props.service?.history ?? []}
                cardTable
            />
        </Collapse>
    )
}
