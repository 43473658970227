import { Badge } from 'front'
import { useContext } from 'react'
import { getNcsIcon } from '../../../../utils'
import Sidebar from '../../../front/sidebar/Sidebar'
import EmployeeDropdown from '../../../utils/EmployeeDropdown'
import { useEmployeeBaseLine } from '../api/projectSearch.api'
import { Context } from '../Context/GlobalState'
import { sortBy } from 'lodash'
import Cookies from 'js-cookie'
const employee = Cookies.getJSON('employee')
const getEmpID = () => employee?.EmpID
const emp_id = getEmpID()

export default function NavQueue({ aggs, setFilters, filters, workflowFilter, setWorkflowFilter }) {
    // console.log('🚀 ~ file: NavQueue.js:15 ~ NavQueue ~ filters:', filters)
    const { dropdowns } = useContext(Context)
    const { data: baseLine } = useEmployeeBaseLine(filters?.employee_id)

    let importCount = 0
    let portalCount = 0
    let desktopCount = 0

    baseLine?.list?.map((i) =>
        i?.workflow === 'PORTAL'
            ? portalCount++
            : i?.workflow === 'DESKTOP'
            ? desktopCount++
            : importCount++
    )

    // const NavQueueEmps = sortBy(dropdowns?.employee, ['FirstName', 'LastName'])

    return (
        <>
            {dropdowns?.employee?.length > 0 && (
                <div className={'mb-3'}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <EmployeeDropdown
                                employees={sortBy(dropdowns?.employee, ['FirstName', 'LastName'])}
                                onChange={(employee_id) => {
                                    employee_id ? setFilters({ employee_id }) : setFilters({})
                                }}
                                selected={filters?.employee_id}
                                clearsToCurrentUser
                                clearsToCurrentUserLabel='My Queue'
                            />
                        </div>
                    </div>
                </div>
            )}
            <Sidebar.Nav pills>
                <Sidebar.NavLink
                    active={workflowFilter === false}
                    onClick={() => {
                        setWorkflowFilter(false)
                    }}
                    icon='fal fa-list-ul'
                >
                    <div className={'flex-fill d-flex justify-content-between'}>
                        <div>All</div>
                        {(baseLine?.list?.length ?? 0) != 0 && (
                            <Badge className='d-block' bg='primary' pill>
                                {baseLine?.list?.length}
                            </Badge>
                        )}
                    </div>
                </Sidebar.NavLink>
                <Sidebar.NavLink
                    active={workflowFilter === null}
                    onClick={() => {
                        setWorkflowFilter(workflowFilter === null ? false : null)
                    }}
                    icon='fal fa-download'
                >
                    <div className={'flex-fill d-flex justify-content-between'}>
                        <div>Import</div>
                        {importCount != 0 && (
                            <Badge className='d-block' bg='primary' pill>
                                {importCount}
                            </Badge>
                        )}
                    </div>
                </Sidebar.NavLink>

                <Sidebar.NavLink
                    active={workflowFilter === 'PORTAL'}
                    onClick={() => {
                        setWorkflowFilter(workflowFilter === 'PORTAL' ? false : 'PORTAL')
                    }}
                    icon='fal fa-helmet-safety'
                >
                    <div className={'flex-fill d-flex justify-content-between'}>
                        <div>Portal</div>
                        {portalCount != 0 && (
                            <Badge className='d-block' bg='primary' pill>
                                {portalCount}
                            </Badge>
                        )}
                    </div>
                </Sidebar.NavLink>
                {/* 
                <Sidebar.NavLink
                    active={workflowFilter === 'DESKTOP'}
                    onClick={() => {
                        setWorkflowFilter(workflowFilter === 'DESKTOP' ? false : 'DESKTOP')
                    }}
                    icon='fal fa-desktop'
                >
                    <div className={'flex-fill d-flex justify-content-between'}>
                        <div>Desktop</div>
                        {desktopCount != 0 && (
                            <Badge className='d-block' bg='primary' pill>
                                {desktopCount}
                            </Badge>
                        )}
                    </div>
                </Sidebar.NavLink> */}

                <Sidebar.Divider />
                <Sidebar.NavHeader className='mt-4 d-flex justify-content-between'>
                    <span>Stage</span>
                </Sidebar.NavHeader>
                <Sidebar.NavLink
                    icon='fal fa-bars'
                    active={!filters?.current_stage}
                    onClick={() => {
                        setFilters((current) => {
                            const currentFilters = { ...current }
                            delete currentFilters?.current_stage
                            return currentFilters
                        })
                    }}
                >
                    <div className={'flex-fill d-flex justify-content-between'}>
                        <div>All</div>
                        {baseLine?.aggs?.all != 0 && (
                            <Badge className='d-block' bg='primary' pill>
                                {baseLine?.aggs?.all}
                            </Badge>
                        )}
                    </div>
                </Sidebar.NavLink>
                {baseLine?.aggs?.current_stage
                    ?.sort((a, b) => {
                        const stageA = dropdowns?.['task.stage']?.find(
                            (stage) => stage?.code === a?.id
                        )
                        const stageB = dropdowns?.['task.stage']?.find(
                            (stage) => stage?.code === b?.id
                        )
                        return stageA?.sort - stageB?.sort
                    })
                    ?.map((queue, i) => {
                        const selected = filters?.current_stage == queue?.id
                        return (
                            <Sidebar.NavLink
                                key={i}
                                active={selected}
                                icon={getNcsIcon({
                                    dept: 'nml',
                                    category: 'taskStage',
                                    value: queue?.id,
                                    fallback: 'folder',
                                })}
                                onClick={() => {
                                    setFilters((current) => {
                                        let filters = { ...current }
                                        if (selected) {
                                            delete filters.current_stage
                                        } else {
                                            filters.current_stage = [queue?.id]
                                        }
                                        return filters
                                    })
                                }}
                                className='d-block'
                            >
                                <div className={'w-100 d-flex justify-content-between'}>
                                    <div className='text-capitalize'>
                                        {queue.name.toLowerCase()}
                                    </div>
                                    {queue?.count != 0 && (
                                        <Badge className='d-block' bg='primary' pill>
                                            {queue.count}
                                        </Badge>
                                    )}
                                </div>
                            </Sidebar.NavLink>
                        )
                    })}

                <Sidebar.Divider />
                <Sidebar.NavHeader className='mt-4 d-flex justify-content-between'>
                    <span>Tasks</span>
                </Sidebar.NavHeader>
                <Sidebar.NavLink
                    icon='fal fa-bars'
                    active={!filters?.task_category}
                    onClick={() => {
                        setFilters((current) => {
                            const currentFilters = { ...current }
                            delete currentFilters?.task_category
                            return currentFilters
                        })
                    }}
                >
                    <div className={'flex-fill d-flex justify-content-between'}>
                        <div>All</div>
                        {baseLine?.aggs?.all != 0 && (
                            <Badge className='d-block' bg='primary' pill>
                                {baseLine?.aggs?.all}
                            </Badge>
                        )}
                    </div>
                </Sidebar.NavLink>
                {baseLine?.aggs?.task_category?.map((queue, i) => {
                    const selected = filters?.task_category == queue?.id
                    return (
                        <Sidebar.NavLink
                            key={i}
                            active={selected}
                            icon={getNcsIcon({
                                dept: 'nml',
                                category: 'taskCategory',
                                value: queue?.id,
                                fallback: 'folder',
                            })}
                            onClick={() => {
                                setFilters((current) => {
                                    let filters = { ...current }
                                    if (selected) {
                                        delete filters.task_category
                                    } else {
                                        filters.task_category = queue?.id
                                    }
                                    return filters
                                })
                            }}
                            className='d-block'
                        >
                            <div className={'w-100 d-flex justify-content-between'}>
                                <div className=''>{queue.name}</div>
                                {queue?.count != 0 && (
                                    <Badge className='d-block' bg='primary' pill>
                                        {queue.count}
                                    </Badge>
                                )}
                            </div>
                        </Sidebar.NavLink>
                    )
                })}

                <Sidebar.Divider />
                <Sidebar.NavHeader className='mt-4 d-flex justify-content-between'>
                    <span>Priority</span>
                </Sidebar.NavHeader>
                <Sidebar.NavLink
                    icon='fal fa-bars'
                    active={JSON.stringify(filters?.is_priority) == '[]'}
                    onClick={() => {
                        setFilters((current) => ({ ...current, is_priority: [] }))
                    }}
                >
                    <div className={'flex-fill d-flex justify-content-between'}>
                        <div>All</div>
                        {baseLine?.aggs?.all > 0 && (
                            <Badge className='d-block' bg='primary' pill>
                                {baseLine?.aggs?.all}
                            </Badge>
                        )}
                    </div>
                </Sidebar.NavLink>
                {baseLine?.aggs?.is_priority?.map((queue, i) => {
                    if (queue?.id?.length == 0) return
                    const selected = JSON.stringify(filters?.is_priority) == `[${queue?.id}]`
                    return (
                        <Sidebar.NavLink
                            key={i}
                            active={selected}
                            icon={queue.name == '0' ? 'fal fa-play' : 'fal fa-forward'}
                            onClick={() => {
                                setFilters((current) => {
                                    let filters = { ...current }
                                    if (selected) {
                                        delete filters.is_priority
                                    } else {
                                        filters.is_priority = [queue?.id]
                                    }
                                    return filters
                                })
                            }}
                            className='d-block'
                        >
                            <div className={'w-100 d-flex justify-content-between'}>
                                <div className=''>
                                    {queue.name == '0'
                                        ? 'Standard'
                                        : queue.name == '1'
                                        ? 'Priority'
                                        : 'All'}
                                </div>
                                {queue?.count != 0 && (
                                    <Badge
                                        className='d-block'
                                        bg={queue.name == '0' ? 'primary' : 'danger'}
                                        pill
                                    >
                                        {queue.count ?? 0}
                                    </Badge>
                                )}
                            </div>
                        </Sidebar.NavLink>
                    )
                })}

                <Sidebar.Divider />
                <Sidebar.NavHeader className='mt-4 d-flex justify-content-between'>
                    <span>Created</span>
                </Sidebar.NavHeader>
                <Sidebar.NavLink
                    icon='fal fa-bars'
                    active={JSON.stringify(filters?.created_date) == '[]'}
                    onClick={() => {
                        setFilters((current) => ({ ...current, created_date: [] }))
                    }}
                >
                    <div className={'flex-fill d-flex justify-content-between'}>
                        <div>All</div>
                        <Badge className='d-block' bg='primary' pill>
                            {baseLine?.aggs?.all ?? 0}
                        </Badge>
                    </div>
                </Sidebar.NavLink>
                {baseLine?.aggs?.created_date?.map((queue, i) => {
                    if (queue?.id?.length == 0) return
                    const selected = JSON.stringify(filters?.created_date) == `[${queue?.id}]`
                    return (
                        <Sidebar.NavLink
                            key={i}
                            active={selected}
                            icon={
                                'fal fa-' +
                                getNcsIcon({
                                    dept: 'nml',
                                    category: 'createdDate',
                                    value: queue?.id,
                                    fallback: 'folder',
                                })
                            }
                            onClick={() => {
                                setFilters((current) => {
                                    let filters = { ...current }
                                    if (selected) {
                                        delete filters.created_date
                                    } else {
                                        filters.created_date = [queue?.id]
                                    }
                                    return filters
                                })
                            }}
                            className='d-block'
                        >
                            <div className={'w-100 d-flex justify-content-between'}>
                                <div className=''>{queue.name}</div>
                                {queue?.count != 0 && (
                                    <Badge className='d-block' bg={'primary'} pill>
                                        {queue.count ?? 0}
                                    </Badge>
                                )}
                            </div>
                        </Sidebar.NavLink>
                    )
                })}

                {/* <Sidebar.Divider />

                <Sidebar.NavHeader>Folders</Sidebar.NavHeader>

                <Sidebar.NavLink href='#' icon='fal fa-folder'>
                    Quatrro
                </Sidebar.NavLink>

                <Sidebar.NavLink href='#' icon='fal fa-folder'>
                    Waiting on Client
                </Sidebar.NavLink> */}
            </Sidebar.Nav>
        </>
    )
}
