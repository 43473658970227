import classnames from 'classnames'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Button from '../components/Button'

export const CollapsingHeaderContext = React.createContext({})
const IsTopComponent = React.createContext(true)

export function CollapsingHeaderWrapper({ children }) {
    const [collapsed, setCollapsed] = useState(false)
    const [pinned, setPinned] = useState(false)
    const [scrolled, setScrolled] = useState(false)
    const [transitionTime, setTransitionTime] = useState(0.3)
    const [stickyHeight, setStickyHeight] = useState()
    const [belowHeight, setBelowHeight] = useState()

    function setHeaderCollapsed(value) {
        setCollapsed(value)

        if (value) {
            fadeOut('disappear-on-collapse')
            window.setTimeout(() => {
                fadeIn('appear-on-collapse')
            }, transitionTime * 500)
        } else {
            fadeOut('appear-on-collapse')
            window.setTimeout(() => {
                fadeIn('disappear-on-collapse')
            }, transitionTime * 500)
        }
    }

    function fadeIn(className) {
        let appearElements = document.querySelectorAll(`.collapsing-header .${className}`)

        ;[...appearElements]?.forEach((e) => {
            if (e.classList.contains(`${className}-transition`)) {
                e.style.display = 'initial'
                window.setTimeout(() => {
                    e.style.opacity = 1
                }, 50)
            } else {
                e.style.display = 'initial'
            }
        })
    }

    function fadeOut(className) {
        let disappearElements = document.querySelectorAll(`.collapsing-header .${className}`)

        ;[...disappearElements]?.forEach((e) => {
            if (e.classList.contains(`${className}-transition`)) {
                e.style.opacity = 0
                window.setTimeout(() => {
                    e.style.display = 'none'
                }, transitionTime * 500)
            } else {
                e.style.display = 'none'
            }
        })
    }

    return (
        <CollapsingHeaderContext.Provider
            value={{
                collapsed,
                setHeaderCollapsed,
                pinned,
                setPinned,
                scrolled,
                setScrolled,
                transitionTime,
                setTransitionTime,
                stickyHeight,
                setStickyHeight,
                belowHeight,
                setBelowHeight,
            }}
        >
            <div id='collapsingHeaderWrapper'>{children}</div>
        </CollapsingHeaderContext.Provider>
    )
}

export default function CollapsingHeader({
    children,
    className,
    pinButton,
    expandButton,
    transitionSeconds,
    findCollapsedHeight,
}) {
    const [collapsedHeight, setCollapsedHeight] = useState(findCollapsedHeight() ?? 120)
    const {
        collapsed,
        setHeaderCollapsed,
        pinned,
        setPinned,
        scrolled,
        setScrolled,
        transitionTime,
        setTransitionTime,
        setStickyHeight,
        setBelowHeight,
    } = useContext(CollapsingHeaderContext)
    const ref = useRef()

    let collapsingHeaderUnder = document.getElementById('collapsingHeaderUnder')
    let fullHeight = collapsingHeaderUnder ? collapsingHeaderUnder.clientHeight : 'initial'

    useEffect(() => {
        setTransitionTime(transitionSeconds)
    }, [transitionSeconds])

    useEffect(() => {
        window.onscroll = function () {
            scrollFunction()
        }
    })

    useEffect(() => {
        window.onresize = function () {
            setCollapsedHeight(findCollapsedHeight())
        }
    })

    useEffect(() => {
        let currentHeight = collapsed ? collapsedHeight : fullHeight
        let bottom = currentHeight + 62
        let windowHeight = window.innerHeight

        setStickyHeight(bottom)
        setBelowHeight(windowHeight - bottom)
    }, [collapsed])

    function scrollFunction() {
        if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
            if (!pinned) setHeaderCollapsed(true)
            setScrolled(true)
        } else {
            if (!pinned) setHeaderCollapsed(false)
            setScrolled(false)
        }
        setCollapsedHeight(findCollapsedHeight())
    }

    return (
        <>
            <IsTopComponent.Provider value={true}>
                <div
                    className={classnames(
                        'collapsing-header container-fluid page-header',
                        className,
                        {
                            'bg-white': scrolled,
                            collapsed: collapsed,
                            scrolled: scrolled,
                        }
                    )}
                    style={{
                        height: collapsed ? collapsedHeight : fullHeight,
                        transition: `${transitionTime}s`,
                    }}
                    // ref={ref}
                    id='collapsingHeader'
                >
                    {expandButton && scrolled && (
                        <button
                            type='button'
                            className='navbar-aside-toggler expand-button'
                            onClick={() => {
                                setHeaderCollapsed(!collapsed)
                                setPinned(false)
                            }}
                        >
                            <i
                                className={classnames('fal', {
                                    'fa-chevrons-down': collapsed,
                                    'fa-chevrons-up': !collapsed,
                                })}
                            />
                        </button>
                    )}
                    {pinButton && !collapsed && (
                        <button
                            type='button'
                            className='navbar-aside-toggler pin-button'
                            onClick={() => {
                                setPinned(!pinned)
                            }}
                        >
                            <span className='fa-stack fa-1x'>
                                <i
                                    className={classnames('fas fa-thumbtack fa-stack-1x', {
                                        'fa-rotate-45': !pinned,
                                    })}
                                    style={{
                                        left: '-5px',
                                        transition: '.2s',
                                        opacity: pinned ? 1 : 0,
                                    }}
                                />
                                <i
                                    className={classnames('fal fa-thumbtack fa-stack-1x', {
                                        'fa-rotate-45': !pinned,
                                    })}
                                    style={{
                                        left: '-6px',
                                        transition: '.2s',
                                        opacity: !pinned ? 1 : 0,
                                    }}
                                />
                            </span>
                        </button>
                    )}
                    {children}
                </div>
            </IsTopComponent.Provider>
            <IsTopComponent.Provider value={false}>
                <div
                    className={classnames(
                        'collapsing-header-under container-fluid page-header bg-white'
                    )}
                    style={{ opacity: 0 }}
                    // ref={ref}
                    id='collapsingHeaderUnder'
                >
                    {children}
                </div>
            </IsTopComponent.Provider>
        </>
    )
}

function CollapsingHeaderTransition({
    as: Component = 'div',
    children,
    className,
    expandedClasses,
    collapsedClasses,
    expandedStyle,
    collapsedStyle,
}) {
    const { collapsed, transitionTime } = useContext(CollapsingHeaderContext)
    const isTopComponent = useContext(IsTopComponent)

    let currentStyles = collapsed && isTopComponent ? collapsedStyle : expandedStyle

    return (
        <Component
            className={classnames('collapsing-header-transition', className, {
                [expandedClasses]: !isTopComponent || !collapsed,
                [collapsedClasses]: isTopComponent && collapsed,
            })}
            style={{
                transition: `${transitionTime / 2}s`,
                ...currentStyles,
            }}
        >
            {children}
        </Component>
    )
}

function CollapsingHeaderDisappear({ as: Component = 'div', children, transition }) {
    const { transitionTime } = useContext(CollapsingHeaderContext)

    return (
        <Component
            className={classnames('disappear-on-collapse', {
                'disappear-on-collapse-transition': transition,
            })}
            style={{ transition: `${transitionTime / 2}s` }}
        >
            {children}
        </Component>
    )
}

function CollapsingHeaderAppear({ as: Component = 'div', children, transition }) {
    const { scrolled, transitionTime } = useContext(CollapsingHeaderContext)
    const isTopComponent = useContext(IsTopComponent)
    const [firstScrollEventOccurred, setFirstScrollEventOccurred] = useState(false)

    useEffect(() => {
        if (scrolled && !firstScrollEventOccurred) setFirstScrollEventOccurred(true)
    }, [scrolled])

    if (!isTopComponent) return <></>

    return (
        <Component
            className={classnames('appear-on-collapse', {
                'before-first-scroll': !firstScrollEventOccurred,
                'appear-on-collapse-transition': transition,
            })}
            style={{ transition: `${transitionTime / 2}s` }}
        >
            {children}
        </Component>
    )
}

const CollapsingHeaderButton = React.forwardRef(
    (
        {
            children,
            className,
            expandedClasses,
            collapsedClasses,
            expandedStyle,
            collapsedStyle,
            collapsedSize,
            expandedSize,
            ...rest
        },
        ref
    ) => {
        const { collapsed, transitionTime } = useContext(CollapsingHeaderContext)
        const isTopComponent = useContext(IsTopComponent)
        let currentStyles = collapsed && isTopComponent ? collapsedStyle : expandedStyle

        return (
            <Button
                {...rest}
                ref={ref}
                size={isTopComponent && collapsed ? collapsedSize : expandedSize}
                className={classnames('collapsing-header-transition', className, {
                    [expandedClasses]: !(isTopComponent && collapsed),
                    [collapsedClasses]: isTopComponent && collapsed,
                })}
                style={{
                    transition: `${transitionTime / 2}s`,
                    ...rest.style,
                    ...currentStyles,
                }}
            >
                {children}
            </Button>
        )
    }
)

CollapsingHeader.Disappear = CollapsingHeaderDisappear
CollapsingHeader.Appear = CollapsingHeaderAppear
CollapsingHeader.Transition = CollapsingHeaderTransition
CollapsingHeader.Button = CollapsingHeaderButton
