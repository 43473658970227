import React, { useContext, useEffect, useState } from 'react'
import { Alert, Col, Modal, Row } from 'react-bootstrap'
import { Button, Form } from '../../../../../front'
import { PortalContext } from '../../../../../GlobalState'
import { useDeleteFee, useUpdateFee } from '../../../api/fees.api'
import { ProjectContext } from '../../../Context/ProjectContext'
import { Collapse } from '@/components/utils'
import { findCurrencyValues } from 'tracker/Utils/findCurrencyValues'

export default function EditFeeModal({ show, onHide, fee }) {
    const [unitRate, setUnitRate] = useState(fee.unit_rate)
    const [total, setTotal] = useState(parseFloat(fee.amount))
    const [breakdown, setBreakdown] = useState(fee.breakdown || '')
    const [breakdownFees, setBreakdownFees] = useState('0.00')

    const { task_id: taskId, id: feeId } = fee
    const { projectId } = useContext(ProjectContext)
    const {
        employee: { admin },
    } = useContext(PortalContext)

    const { mutate: updateFee, ...updateFeeApi } = useUpdateFee(
        { projectId, taskId, feeId },
        { onSuccess: onHide }
    )

    const { mutate: deleteFee, ...deleteFeeApi } = useDeleteFee(
        { projectId, taskId, feeId },
        { onSuccess: onHide }
    )

    const submitUpdate = () => updateFee({ unit_rate: unitRate, breakdown })

    const confirmDeleteFee = () => {
        if (confirm('Are you sure you want to delete his fee?')) {
            deleteFee()
        }
    }

    useEffect(() => {
        setTotal(unitRate * fee.quantity)
    }, [unitRate])

    useEffect(() => {
        setUnitRate(fee.unit_rate)
        setTotal(parseFloat(fee.amount))
        setBreakdown(fee.breakdown || '')
    }, [show])

    useEffect(() => {
        findFees()
    }, [breakdown])

    function findFees() {
        let unit_rate = 0
        const fees = findCurrencyValues(breakdown)
        if (fees.length > 0) {
            fees.forEach((fee) => {
                unit_rate += parseFloat(fee.replace(/[^0-9.-]+/g, ''))
            })
            setBreakdownFees(unit_rate.toFixed(2).toString())
        } else {
            setBreakdownFees('0.00')
        }
    }

    return (
        <Modal centered show={show} onHide={onHide} size='sm'>
            <Modal.Header closeButton>
                <Modal.Title>{fee.description}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Row} className='mb-3'>
                    <Form.Label column lg={6} className='text-end pe-8'>
                        Price per unit
                    </Form.Label>
                    <Col>
                        <Form.InputGroup inputGroupMerge size='sm'>
                            <Form.InputGroup.Text prepend className='ps-2'>
                                <i className='fal fa-dollar-sign' />
                            </Form.InputGroup.Text>
                            <Form.Control
                                className='text-end'
                                name='unit_rate'
                                value={unitRate}
                                onChange={(e) => {
                                    setUnitRate(e.target.value)
                                }}
                            />
                        </Form.InputGroup>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className='mb-3'>
                    <Form.Label column lg={6} className='text-end pe-8'>
                        Quantity
                    </Form.Label>
                    <Form.Label column lg={6} className='d-flex justify-content-between'>
                        <div>
                            <i className='fal fa-times ms-2' />
                        </div>
                        <div className='me-3'>{fee.quantity}</div>
                    </Form.Label>
                </Form.Group>
                <Form.Group as={Row} className='mb-3'>
                    <Form.Label column lg={6} />
                    <Form.Label
                        column
                        lg={6}
                        className='d-flex justify-content-between'
                        style={{ borderTop: '1px solid var(--bs-gray-300)' }}
                    >
                        <div>
                            <i className='fal fa-dollar-sign ms-2' />
                        </div>
                        <div className='me-3'>{total.toFixed(2)}</div>
                    </Form.Label>
                </Form.Group>
                <Collapse
                    title={<Form.Label>Breakdown</Form.Label>}
                    startOpen={breakdown?.length > 0}
                >
                    <div>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Control
                                as='textarea'
                                rows={6}
                                value={breakdown}
                                onChange={({ target }) => {
                                    setBreakdown(target.value)
                                }}
                                ignoreGlobalDisable={true}
                            />
                        </Form.Group>
                        {breakdownFees > 0 && breakdownFees != unitRate && (
                            <div className='text-center w-100'>
                                <Button
                                    variant='link'
                                    className='p-0'
                                    onClick={() => {
                                        setUnitRate(breakdownFees)
                                    }}
                                >
                                    Update Price to ${breakdownFees}?
                                </Button>
                            </div>
                        )}
                    </div>
                </Collapse>
                {updateFeeApi?.isError && (
                    <Alert variant='soft-danger'>
                        <div className='d-flex'>
                            <div className='flex-shrink-0'>
                                <i className='fas fa-circle-exclamation' />
                            </div>
                            <div className='flex-grow-1 ms-2'>Error editing fee</div>
                        </div>
                    </Alert>
                )}
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <div>
                    <Button onClick={confirmDeleteFee} ghost variant='danger'>
                        Delete
                    </Button>
                </div>
                <div>
                    <Button onClick={onHide} ghost variant='secondary' className='me-2'>
                        Cancel
                    </Button>
                    <Button
                        onClick={submitUpdate}
                        icon={
                            updateFeeApi?.isLoading || deleteFeeApi?.isLoading
                                ? 'fal fa-spinner-third fa-spin'
                                : null
                        }
                        variant='primary'
                    >
                        Save
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
