import classnames from 'classnames'
import Link from 'next/link'
import { useContext } from 'react'
import Nav from '../components/Nav'
import { LayoutContext } from '../layouts/FrontLayout'
import { popoutWindow } from '@/components/utils/popoutWindow'
import { Button } from 'front'
import { ProjectContext } from 'tracker/Context/ProjectContext'

export default function Sidebar({ children, collapsible }) {
    const { leftSidebarCollapsed } = useContext(LayoutContext)

    return (
        <aside
            className={
                'vertical-aside-below-navbar navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered bg-white navbar-vertical-aside-initialized' +
                (leftSidebarCollapsed ? ' sidebar-collapsed' : '')
            }
        >
            <div className='navbar-vertical-container'>
                <div className='navbar-vertical-footer-offset'>
                    {collapsible && <SidebarCollapseToggle />}
                    {children}
                </div>
            </div>
        </aside>
    )
}

function SidebarCollapseToggle({}) {
    const { leftSidebarCollapsed, setLeftSidebarCollapsed } = useContext(LayoutContext)

    return (
        <button
            onClick={() => {
                setLeftSidebarCollapsed(!leftSidebarCollapsed)
            }}
            type='button'
            className='js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler'
            style={{ opacity: '1' }}
        >
            {leftSidebarCollapsed ? (
                <i className='fal fa-arrow-from-left' />
            ) : (
                <i className='fal fa-arrow-from-right' />
            )}
        </button>
    )
}

function SidebarHeader({ href, title, logo, collapsed }) {
    return (
        <Link href={href} passHref>
            <a
                className='dropdown-item vertical-aside-header navbar-brand sidebar-header'
                href={href}
            >
                <div className='d-flex align-items-center'>
                    <div className='flex-shrink-0'>
                        <div className='vertical-aside-logo avatar' style={{ height: 'initial' }}>
                            {logo}
                        </div>
                    </div>
                    <div className='flex-grow-1 ms-2'>
                        <h4
                            className={`mb-0 sidebar-header-title ${
                                collapsed ? 'transparent' : ''
                            }`}
                        >
                            {title}
                        </h4>
                    </div>
                </div>
            </a>
        </Link>
    )
}

function SidebarContent({ children }) {
    return <div className='navbar-vertical-content'>{children}</div>
}

function SidebarNav({ children, props }) {
    return (
        <div className='navbar-expand-lg navbar-vertical mb-3 mb-lg-5'>
            <Nav {...props} vertical variant='pills' className='card-navbar-nav'>
                {children}
            </Nav>
        </div>
    )
}

function SidebarNavHeader({ children, className, style }) {
    return (
        <>
            <span className={classnames('dropdown-header', className)} style={style}>
                {children}
            </span>
            <small className='fal fa-ellipsis nav-subtitle-replacer' />
        </>
    )
}

function SidebarDropdown({
    children,
    id,
    icon = 'fad fa-circle-dot fa-xs',
    title,
    active,
    ...rest
}) {
    return (
        <Nav.Item id={id} {...rest}>
            <SidebarNavLink
                icon={icon}
                className={'dropdown-toggle'}
                data-bs-toggle='collapse'
                data-bs-target={`#${id}-items`}
            >
                {title}
            </SidebarNavLink>
            <div
                id={`${id}-items`}
                className={classnames('nav-collapse collapse', {
                    show: active,
                })}
                data-bs-parent={`#${id}`}
            >
                {children}
            </div>
        </Nav.Item>
    )
}

function SidebarDropdownItem({ children, ...bsProps }) {
    return <Nav.Link {...bsProps}>{children}</Nav.Link>
}

function SidebarNavLink({
    children,
    icon = 'fad fa-circle-dot fa-xs',
    externalLinkSymbol = false,
    ...bsProps
}) {
    return (
        <div className={classnames('d-flex align-items-center justify-content-between mb-1 w-100')}>
            <Nav.Link
                {...bsProps}
                className={classnames('lh-normal mb-0 flex-grow-1', {
                    'external-link': externalLinkSymbol,
                })}
            >
                {icon && <Nav.Icon icon={icon} className='fa-fw' />}
                <div className='nav-link-title w-100'>{children}</div>
            </Nav.Link>
            {bsProps?.popout && <PopoutWindow {...bsProps?.popout} />}
        </div>
    )
}

function SidebarDivider() {
    return <div className='dropdown-divider' />
}

function SidebarFooter({ children }) {
    return (
        <div className='navbar-vertical-footer'>
            <div
                className={'d-flex justify-content-center h-100 align-items-center'}
                style={{ minHeight: 25 }}
            >
                {children}
            </div>
        </div>
    )
}

function SidebarFooterList({ children }) {
    return <ul className='navbar-vertical-footer-list'>{children}</ul>
}

function SidebarFooterListItem({ children }) {
    return <li className='navbar-vertical-footer-list-item'>{children}</li>
}

function PopoutWindow({ windowName, url, title = 'New Window' }) {
    const { openProjectPopoutWindow } = useContext(ProjectContext)
    return (
        <Button
            icon='fal fa-external-link fa-lg'
            size='sm'
            ghost
            variant='secondary'
            className='ms-1'
            style={{
                borderRadius: 8,
                padding: '6px 9px',
            }}
            onClick={() => {
                openProjectPopoutWindow(windowName, url, title, 1000, 900)
            }}
        />
    )
}

Sidebar.Header = SidebarHeader

Sidebar.Content = SidebarContent
Sidebar.Nav = SidebarNav
Sidebar.NavHeader = SidebarNavHeader
Sidebar.Dropdown = SidebarDropdown
Sidebar.DropdownItem = SidebarDropdownItem
Sidebar.NavItem = Nav.Item
Sidebar.NavLink = SidebarNavLink
Sidebar.NavIcon = Nav.Icon
Sidebar.Divider = SidebarDivider

Sidebar.Footer = SidebarFooter
Sidebar.FooterList = SidebarFooterList
Sidebar.FooterListItem = SidebarFooterListItem
Sidebar.PopoutWindow = PopoutWindow
