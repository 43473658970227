import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { mergeConfigs } from '../../../../utils'
import axios from '../Context/AxiosSetup'
import DwApi from '@/components/docuware/api/DwApi'

export class Project {
    static keys = {
        all: ['project'],
        project: (projectId) => [...this.keys.all, projectId],
        researchCards: (projectId) => [...this.keys.project(projectId), 'researchCards'],
    }

    static get = (projectId, takeover = true) => {
        return axios
            .get(`/nml/tracker/2/project/${projectId}/json?takeover=${takeover}`)
            .then(({ data }) => data)
            .then(async (data) => {
                if (data.client.div_id) {
                    const contacts = await DwApi.getContacts(data.client.div_id)
                    return { ...data, contacts }
                }
                return data
            })
    }

    static update = (projectId, data) => {
        return axios
            .patch(`/nml/tracker/2/version/${projectId}/json`, data)
            .then((response) => response.data)
    }

    static publish = (projectId, data) => {
        return axios
            .post(`/nml/tracker/2/version/publish/${projectId}/json`, data)
            .then((response) => response.data)
    }

    static refreshProject = (projectId) => {
        return axios
            .post(`/nml/tracker/2/version/refresh/${projectId}/json?takeover=false`)
            .then((response) => response.data)
    }

    static takeoverLock = (projectId) => {
        return axios
            .post(`/nml/tracker/2/version/refresh/${projectId}/json?takeover=true`)
            .then((response) => response.data)
    }
    static getAllRequests = (projectId) => {
        return axios
            .get(`/nml/tracker/1/project/${projectId}/request/history/json`)
            .then((response) => response.data)
    }

    static getResearchCards = (projectId) => {
        return axios
            .get(`/nml/tracker/1/research/cards/${projectId}/json`)
            .then((response) => response.data)
    }
}

export const useProject = ({ projectId, takeover = false }, config) => {
    const projectIdInUrl = useRouter().query?.project_id

    if (projectIdInUrl && !projectId) {
        projectId = parseInt(projectIdInUrl)
    }

    return useQuery(
        Project.keys.project(projectId),
        () => Project.get(projectId, takeover),
        mergeConfigs(
            {
                enabled: !!projectId,
            },
            config
        )
    )
}

export const useUpdateProject = ({ projectId }, config) => {
    const projectIdInUrl = useRouter().query?.project_id

    if (projectIdInUrl && !projectId) {
        projectId = parseInt(projectId)
    }

    const queryClient = useQueryClient()

    return useMutation(
        (data) => Project.update(projectId, data),
        mergeConfigs(
            {
                onSuccess: (data) => {
                    queryClient.setQueryData(Project.keys.project(projectId), {
                        ...queryClient.getQueryData(Project.keys.project(projectId)),
                        ...data,
                    })
                },
            },
            config
        )
    )
}

export const usePublishProject = ({ projectId }, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        () => Project.publish(projectId, { public: false }),
        mergeConfigs(
            {
                onSuccess: (data) => {
                    queryClient.setQueryData(Project.keys.project(projectId), {
                        ...queryClient.getQueryData(Project.keys.project(projectId)),
                        ...data,
                    })
                },
            },
            config
        )
    )
}

export const useTakeoverProject = ({ projectId }, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        () => !isNaN(projectId) && Project.takeoverLock(projectId),
        mergeConfigs(
            {
                onSuccess: (data) => {
                    queryClient.setQueryData(Project.keys.project(projectId), {
                        ...queryClient.getQueryData(Project.keys.project(projectId)),
                        ...data,
                    })
                },
            },
            config
        )
    )
}

export const useRefreshProject = ({ projectId }, config) => {
    const projectIdInUrl = useRouter().query?.project_id

    if (projectIdInUrl && !projectId) {
        projectId = parseInt(projectId)
    }

    const queryClient = useQueryClient()

    return useMutation(
        () => Project.refreshProject(projectId),
        mergeConfigs(
            {
                enabled: !!projectId && !isNaN(projectId),
                onSuccess: (data) => {
                    queryClient.setQueryData(Project.keys.project(projectId), {
                        ...queryClient.getQueryData(Project.keys.project(projectId)),
                        ...data,
                    })
                },
            },
            config
        )
    )
}

export const useProjectRequests = ({ projectId }, config) => {
    const projectIdInUrl = useRouter().query?.project_id

    if (projectIdInUrl && !projectId) {
        projectId = parseInt(projectId)
    }

    const queryClient = useQueryClient()

    return useMutation(
        () => Project.getAllRequests(projectId),
        mergeConfigs(
            {
                enabled: !!projectId,
                onSuccess: (data) => {
                    // console.log('data: ', data)
                    queryClient.setQueryData(Project.keys.project(projectId), {
                        ...queryClient.getQueryData(Project.keys.project(projectId)),
                        ...data,
                    })
                },
            },
            config
        )
    )
}

export const useResearchCards = ({ projectId }, config) => {
    const projectIdInUrl = useRouter().query?.project_id

    if (projectIdInUrl && !projectId) {
        projectId = parseInt(projectId)
    }

    return useQuery(
        Project.keys.researchCards(projectId),
        () => Project.getResearchCards(projectId),
        mergeConfigs(
            {
                enabled: !!projectId,
            },
            config
        )
    )
}
