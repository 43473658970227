import classnames from 'classnames'
import { useModalState } from 'hooks'
import { useContext, useState } from 'react'
import { Alert, Col, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { Badge, CollapsingHeader, GlobalFormContext } from '../../../../front'
import { Tooltip, formatDate } from '../../../../utils'
import { ProjectContext } from '../../Context/ProjectContext'
import { useTrackerDropdowns } from '../../api'
import ClientModal from '../ClientModal'
import ChangesOffcanvas from './ChangesOffcanvas'
import FileBarTabs from './FileBarTabs'
import PublishModal from './PublishModal'
import RecentUsers from './RecentUsers'
import SaveEditPublishMenu from './SaveEditPublishMenu'
import DiscardModal from '../DiscardModal'
import { PortalContext } from '@/components/GlobalState'
import classNames from 'classnames'
import Link from 'next/link'
import CurrentTaskBar from './CurrentTaskBar'

export default function Filebar() {
    const { fileInfo, fileInfoError, fileInfoLoading, changesCount } = useContext(ProjectContext)
    const { employee } = useContext(PortalContext)
    const { data: dropdowns } = useTrackerDropdowns()
    const [showPublishModal, setShowPublishModal] = useState(false)
    const [showChangesOffcanvas, setShowChangesOffcanvas] = useState(false)
    const discardModal = useModalState()

    const projectType = dropdowns?.['project.project_type']?.find(
        (type) => type.code == fileInfo?.project?.project_type
    )?.label
    const debtorType = dropdowns?.['associate.type']?.find(
        (type) => type.id == fileInfo?.associates?.[fileInfo?.project?._debtor_id]?.type_id
    )?.label

    function findCollapsedHeight() {
        const content = document.getElementById('collapsingHeaderContent')?.clientHeight ?? 120
        const tabs = document.getElementById('filebarTabs')?.clientHeight ?? 65
        return content + tabs
    }

    return (
        <>
            <CollapsingHeader
                pinButton
                expandButton
                transitionSeconds={0.3}
                findCollapsedHeight={findCollapsedHeight}
            >
                <div className='d-flex justify-content-between' id='collapsingHeaderContent'>
                    <div className='flex-fill'>
                        {fileInfoError && (
                            <div>
                                <Alert
                                    variant='danger'
                                    className=''
                                    style={{ width: 'fit-content' }}
                                >
                                    <i className='fas fa-circle-exclamation me-2' />
                                    Project failed to load
                                </Alert>
                            </div>
                        )}
                        {fileInfo?.warnings?.import_error && (
                            <div>
                                <Alert
                                    variant='danger'
                                    className=''
                                    style={{ width: 'fit-content' }}
                                >
                                    <i className='fas fa-circle-exclamation me-2' />
                                    Import Error
                                </Alert>
                            </div>
                        )}
                        {!fileInfoError && (
                            <>
                                <FilebarTitle fileInfo={fileInfo} loading={fileInfoLoading} />
                                <div className='d-flex flex-row m-0'>
                                    <FilebarField label='State' loading={!fileInfo}>
                                        {fileInfo?.project?.state}
                                    </FilebarField>
                                    <FilebarField label='Type' loading={!fileInfo}>
                                        {projectType}
                                    </FilebarField>
                                    <FilebarField label='Debtor' loading={!fileInfo}>
                                        {debtorType ? (
                                            <>
                                                {debtorType} (
                                                {
                                                    fileInfo?.associates?.[
                                                        fileInfo?.project?._debtor_id
                                                    ]?.name
                                                }
                                                )
                                            </>
                                        ) : (
                                            <Badge bg='danger' soft>
                                                No debtor
                                            </Badge>
                                        )}
                                    </FilebarField>
                                    <FilebarField label='Furnishings' loading={!fileInfo}>
                                        {formatDate(fileInfo?.project?.first_furnishing_date)} -{' '}
                                        {formatDate(fileInfo?.project?.last_furnishing_date)}
                                    </FilebarField>
                                    <FilebarField label='Address' loading={!fileInfo}>
                                        <Address {...fileInfo?.project} />
                                    </FilebarField>
                                </div>
                            </>
                        )}
                    </div>
                    <div>
                        <Col>
                            <Row>
                                <Col>
                                    <SaveEditPublishMenu
                                        changesCount={changesCount}
                                        setShowChangesOffcanvas={setShowChangesOffcanvas}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className='d-flex justify-content-end align-items-center mt-1'>
                                    <RecentUsers />
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </div>
                <FileBarTabs />
            </CollapsingHeader>
            <CurrentTaskBar />
            <ChangesOffcanvas
                fileInfo={fileInfo}
                showChangesOffcanvas={showChangesOffcanvas}
                setShowChangesOffcanvas={setShowChangesOffcanvas}
                setShowPublishModal={setShowPublishModal}
                showDiscardModal={discardModal.show}
                changesCount={changesCount}
            />
            <PublishModal
                show={showPublishModal}
                onHide={() => {
                    setShowPublishModal(false)
                }}
            />
            <DiscardModal show={discardModal.isOpen} onHide={discardModal.hide} />
        </>
    )
}

export function Address({ address1, address2, city, state, zip, county }) {
    address2 = addAddressComma(address2)
    zip = addAddressComma(zip)
    county = addAddressComma(county)

    return `${address1 ?? ''}${address2} ${city ?? ''} ${state ?? ''}${zip} ${county ?? ''}`
}

function addAddressComma(value) {
    return value ? ', ' + value : ''
}

export function FilebarTitle({ fileInfo, loading, clientEdit = true, preview = false }) {
    const { updateProject, fileInfo: contextFileInfo } = useContext(ProjectContext)
    if (!fileInfo) {
        fileInfo = contextFileInfo
    }
    const clientModal = useModalState(false)
    const { project } = fileInfo ?? {}
    const { is_managed, name, ncs_lno } = project ?? {}

    return (
        <CollapsingHeader.Transition
            as='div'
            className='filebar-title d-flex flex-column m-0'
            expandedClasses='expanded'
            collapsedClasses='collapsed'
        >
            {/* <ProjectIdBadge projectId={fileInfo?.project?.ncs_lno} /> */}
            <h3>
                {loading ? (
                    <Skeleton width='300px' />
                ) : (
                    <div className='d-flex align-items-center'>
                        <div style={{ marginTop: -2 }}>
                            {!is_managed && (
                                <Badge className='me-1' bg='info' style={{ fontSize: 10 }}>
                                    UNMANAGED
                                </Badge>
                            )}
                        </div>
                        <div>
                            {preview && <>{ncs_lno} - </>}
                            {name ? (
                                name
                            ) : (
                                <small className='text-danger fst-italic fw-normal'>
                                    Project name empty
                                </small>
                            )}
                        </div>
                    </div>
                )}
            </h3>
            <ClientButton
                setShowClientModal={clientModal.setIsOpen}
                fileInfo={fileInfo}
                editable={clientEdit}
            />
            {fileInfo?.client?.cli_no && clientModal.isOpen && (
                <ClientModal
                    key={clientModal.key}
                    projectId={fileInfo?.project_id}
                    nat_no={fileInfo?.client?.cli_no}
                    division_id={fileInfo?.project?.division_id}
                    contact_id={fileInfo?.project?.contact_id}
                    closeModal={clientModal.hide}
                    show={clientModal.isOpen}
                    updateProject={updateProject}
                />
            )}
            {/* <TitleBadges fileInfo={fileInfo} /> */}
        </CollapsingHeader.Transition>
    )
}

function ClientButton({ setShowClientModal, fileInfo, editable = true }) {
    const { disableAll } = useContext(GlobalFormContext)
    const [clientHover, setClientHover] = useState(false)
    const { fileInfo: contextFileInfo } = useContext(ProjectContext)
    if (!fileInfo) {
        fileInfo = contextFileInfo
    }

    const hoveredAndEditable = clientHover && !disableAll && editable

    return (
        <div>
            <span
                className={classnames('me-1 fw-bold', {
                    'text-primary': hoveredAndEditable,
                    'text-body': !hoveredAndEditable,
                    pointer: !disableAll && editable,
                })}
                onMouseEnter={() => setClientHover(true)}
                onMouseLeave={() => setClientHover(false)}
                onClick={() => {
                    if (editable) setShowClientModal(!disableAll)
                }}
            >
                {fileInfo?.client?.cli_no && (
                    <span className='fs-smaller'>{fileInfo?.client?.cli_no} - </span>
                )}
                {fileInfo?.client?.name || <Skeleton inline width='150px' />}
                {fileInfo?.client?.contact && (
                    <span className='fs-smaller'> - {fileInfo?.client?.contact}</span>
                )}
                {hoveredAndEditable && <small className='fas fa-pencil ms-1 fa-xs' />}
            </span>
        </div>
    )
}

function ProjectIdBadge({ projectId }) {
    const { copyToClipboard } = useCopyToClipboard()
    const { theme } = useTheme()
    const [showPopup, setShowPopup] = useState(false)

    const onClick = () => {
        setShowPopup(true)
        copyToClipboard(projectId)
        setTimeout(() => setShowPopup(false), 1000)
    }

    return (
        <Tooltip content={'Copied!'} trigger={false} show={showPopup}>
            <Badge
                onClick={onClick}
                bg={theme == 'light' ? 'dark' : 'light'}
                className='me-2 cursor-pointer fs-xs'
                pill
            >
                {projectId}
            </Badge>
        </Tooltip>
    )
}

export function FilebarField({ label, children, loading }) {
    return (
        <div className='me-1'>
            {!loading ? (
                <div className='mb-2 me-3'>
                    {label && (
                        <CollapsingHeader.Disappear transition as='span'>
                            <span className='text-muted slim-label d-sm-none d-md-block mt-3'>
                                {label}
                            </span>
                        </CollapsingHeader.Disappear>
                    )}
                    <span className='text-body text-nowrap text-truncate'>{children}</span>
                </div>
            ) : (
                <Skeleton count={2} width={'125px'} />
            )}
        </div>
    )
}
