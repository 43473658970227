// Pagination Component for adding pagination to a list
// Example at bottom of page

import { Button, Form } from 'front'
import React, { Component } from 'react'
import ScrollableContainer from './ScrollableContainer'
import isEmpty from './isEmpty'

export default class Pagination extends Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef()
        this.scrollContainerRef = React.createRef()
        this.defaultView = 15

        this.state = {
            start: props?.start ?? 0,
            end: props?.end ? props.end : props.view ? props.view - 1 : this.defaultView - 1,
            view: props.view ? props.view : this.defaultView,
            initialView: props.view ? props.view : this.defaultView,
            page: 1,
        }
    }

    componentDidMount() {
        this.props.onRef && this.props.onRef(this)
    }

    componentWillUnmount() {
        this.props.onRef && this.props.onRef(undefined)
    }

    componentDidUpdate(prevProps) {
        // Update this.state.view and this.state.end if the 'view' prop is being used and has changed
        let props = this.props
        let update = {}
        if (props.view !== prevProps.view) {
            update.view = props.view
        }
        if (props?.start && props.start !== prevProps.start) {
            update.start = props.start
        }
        if (props?.end && props.end !== prevProps.end) {
            update.end = props.end
        }

        if (!isEmpty(update)) {
            this.setState(update)
        }
    }

    updateProps() {
        if (this.props.updatePagination) {
            const { view, start, end } = this.state
            this.props.updatePagination({ view, start, end })
        }
    }

    resetPage() {
        this.setState(
            {
                start: 0,
                end: this.state.view - 1,
                page: 1,
            },
            this.updateProps
        )
    }

    goToFirstPage = () => {
        this.setState(
            {
                start: 0,
                end: this.state.view - 1,
                page: 1,
            },
            this.updateProps
        )
        this.scroll()
    }

    goToLastPage = () => {
        this.setState(
            {
                start: this.state.view * this.numberOfFullPages(),
                end: this.props.info.length,
                page: this.numberOfFullPages() + 1,
            },
            this.updateProps
        )
        this.scroll()
    }

    goToNextPage = () => {
        const possibleEnd = this.state.end + this.state.view
        this.setState(
            (prevState) => ({
                start: this.state.start + this.state.view,
                end: possibleEnd > this.props.info.length ? this.props.info.length : possibleEnd,
                page: prevState.page + 1,
            }),
            this.updateProps
        )
        this.scroll()
    }

    goToPreviousPage = () => {
        let end = this.state.end - this.state.view
        let start = this.state.start - this.state.view

        // If we're at the last page, we need to calculate end and start differently than what's done above
        if (this.state.end === this.props.info.length) {
            end = this.numberOfFullPages() * this.state.view - 1
            start = this.numberOfFullPages() * this.state.view - this.state.view
        }

        this.setState(
            (prevState) => ({
                start,
                end,
                page: prevState.page - 1,
            }),
            this.updateProps
        )
        this.scroll()
    }

    numberOfFullPages() {
        // console.log(
        //     'numberOfFullPages',
        //     this.props.info.length / this.state.view,
        //     this.props.info.length,
        //     this.state.view
        // )
        return Math.floor(this.props.info.length / this.state.view)
    }

    pages() {
        const totalPages = this.numberOfFullPages() + 1
        let pages = []
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <option value={i} key={'pageOption' + i}>
                    {i}
                </option>
            )
        }
        return pages
    }

    selectPage(page) {
        this.setState(
            {
                page,
                start: (page - 1) * this.state.view,
                end:
                    page * this.state.view > this.props.info.length
                        ? this.props.info.length
                        : page * this.state.view - 1,
            },
            this.updateProps
        )
        this.scroll()
    }

    scroll() {
        if (this.props.scrollableContainer) {
            const scrollId = this.scrollContainerRef.current.state.id
            document.getElementById(scrollId).scrollTo(0, this.myRef.current.offsetTop)
        } else if (this.myRef.current) {
            window.scrollTo(0, this.myRef.current.offsetTop)
        }
    }

    buttons = () => {
        let perPageOptions = [15, 30, 100]
        if (!perPageOptions.includes(this.state.initialView)) {
            perPageOptions.push(this.state.initialView)
        }
        if (!perPageOptions.includes(this.state.view)) {
            perPageOptions.push(this.state.view)
        }
        perPageOptions.sort(function (a, b) {
            return a - b
        })
        let perPageOptionsHTML = this.perPageOptionsHTML(perPageOptions)

        return (
            this.props.info.length > this.state.initialView && (
                <div className='row'>
                    <div className='col-auto d-flex align-items-center'>
                        <Button
                            size='sm'
                            variant={'light'}
                            disabled={this.state.start <= 0}
                            onClick={this.goToFirstPage}
                        >
                            <span className='fas fa-arrow-to-left' />
                        </Button>
                        <Button
                            size='sm'
                            variant={'light'}
                            className='ms-1'
                            disabled={this.state.start <= 0}
                            onClick={this.goToPreviousPage}
                        >
                            <span className='fas fa-arrow-left' />
                        </Button>
                    </div>
                    <div className={'col-auto d-flex align-items-center'}>
                        {this.state.start + 1} -{' '}
                        {this.state.end >= this.props.info.length
                            ? this.props.info.length
                            : this.state.end + 1}{' '}
                        (of {this.props.info.length})
                    </div>
                    <div className='col-auto d-flex align-items-center'>
                        <Button
                            size='sm'
                            variant={'light'}
                            disabled={this.state.end >= this.props.info.length}
                            onClick={this.goToNextPage}
                        >
                            <span className='fas fa-arrow-right' />
                        </Button>
                        <Button
                            size='sm'
                            className='ms-1'
                            variant={'light'}
                            disabled={this.state.end >= this.props.info.length}
                            onClick={this.goToLastPage}
                        >
                            <span className='fas fa-arrow-to-right' />
                        </Button>
                    </div>
                    <div className={'col-auto d-flex align-items-center'}>Rows:</div>
                    <div className={'col-auto d-flex align-items-center px-0'}>
                        <Form.Select
                            size='sm'
                            onChange={(e) => {
                                let view = Number(e.target.value)
                                this.setState(
                                    { view, start: 0, end: view - 1, page: 1 },
                                    this.updateProps
                                )
                                this.scroll()
                            }}
                            disabled={this.props.info.length < this.state.initialView}
                            value={this.state.view}
                            ignoreGlobalDisable
                        >
                            {perPageOptionsHTML}
                        </Form.Select>
                    </div>
                    <div className={'col-auto d-flex align-items-center'}>Page:</div>
                    <div className={'col-auto d-flex align-items-center px-0'}>
                        <Form.Select
                            size='sm'
                            title='Go to page'
                            onChange={(e) => {
                                this.selectPage(e.target.value)
                            }}
                            disabled={this.numberOfFullPages() === 1}
                            value={this.state.page}
                            ignoreGlobalDisable
                        >
                            {this.pages()}
                        </Form.Select>
                    </div>
                </div>
            )
        )
    }

    perPageOptionsHTML(options) {
        return options.map((val) => (
            <option key={val} value={val}>
                {val}
            </option>
        ))
    }

    render() {
        // console.log('props',this.props)
        // console.log('view',this.state.view,this.state.start,this.state.end)
        if (this.props.info.length < this.state.start) {
            this.setState(
                {
                    start: 0,
                    end: this.state.view - 1,
                    page: 1,
                },
                this.updateProps
            )
        }

        let render = []
        for (let i = this.state.start; i <= this.state.end; i++) {
            const row = this.props.info[i]
            if (row) {
                render.push(row)
            }
        }
        const renderComponent = render.map((result, i) => this.props.render(result, i))

        // If it's a table and it's longer than the value set for max rows per page, show the nav buttons at the bottom
        if (this.props.table && this.props.info.length > this.state.initialView) {
            return (
                <React.Fragment>
                    <div ref={this.myRef} />
                    {renderComponent}
                    {this.props.renderAfter}
                    <tr>
                        <td colSpan={'100%'}>{this.buttons()}</td>
                    </tr>
                </React.Fragment>
            )
        }
        // Else if it's a table shorter than the value set for max rows per page, don't show the buttons
        else if (this.props.table) {
            return (
                <React.Fragment>
                    <div ref={this.myRef} />
                    {renderComponent}
                    {this.props.renderAfter}
                </React.Fragment>
            )
        } else if (this.props.scrollableContainer) {
            return (
                <ScrollableContainer ref={this.scrollContainerRef}>
                    <div ref={this.myRef} />
                    {renderComponent}
                    {this.buttons()}
                    {this.props.renderAfter}
                </ScrollableContainer>
            )
        } else {
            return (
                <React.Fragment>
                    <div ref={this.myRef} />
                    {renderComponent}
                    {this.props.renderAfter}
                    {this.buttons()}
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <div ref={this.myRef} />
                {renderComponent}
                {this.props.renderAfter}
                {this.buttons()}
            </React.Fragment>
        )
    }
}

// EXAMPLE

class Example extends Component {
    resetPagination() {
        this.pagination.resetPage() // Calling this resets page to 1
    }

    render() {
        return (
            <Pagination
                onRef={(ref) => (this.pagination = ref)} // Gets ref for calling child methods
                info={data} // Array of data objects that will be mapped over
                render={(row, i) => {
                    // Function the map should execute for each data object in info array
                    return <div>{row.label}</div>
                }}
            />
        )
    }
}
