import { Button, Card } from 'front'
import Link from 'next/link'
import { useContext, useState } from 'react'
import { Alert, Col, Collapse, Modal, Row } from 'react-bootstrap'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import { useAcceptOls, useRejectOls } from 'tracker/api'
import Skeleton from '../../../../utils/Skeleton'
import InlineChanges from './inlineChanges'
import { formatDate } from '@/components/utils'

export default function QuickMergeModal({ show, onHide, preview, fileInfo }) {
    const { fileInfo: contextFileInfo, canEdit } = useContext(ProjectContext)
    if (!fileInfo) {
        fileInfo = contextFileInfo
    }
    const { mutate: acceptOls, ...acceptOlsApi } = useAcceptOls(fileInfo?.project_id, {
        onSuccess: onHide,
    })
    const { mutate: rejectOls, ...rejectOlsApi } = useRejectOls(fileInfo?.project_id, {
        onSuccess: onHide,
    })

    return (
        <Modal show={show} onHide={onHide} className='ncs-modal' size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>
                    Client Changes
                    <br />
                    <span className='ncs-modal-subtitle'>
                        Accept, reject, or merge client changes
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col className='text-center fs-3 fw-light'>Changes</Col>
                    <Col className='text-center fs-3 fw-light'>Transmittal(s)</Col>
                </Row>
                <Row>
                    <Col>
                        <div className='diff-modal-body'>
                            <div className='changes-popup-body'>
                                {!!fileInfo?.incoming_changes?.all_display ? (
                                    <InlineChanges
                                        changes={fileInfo?.incoming_changes?.all_display}
                                        changesFirst
                                    />
                                ) : (
                                    <>
                                        <div>
                                            <Skeleton variant='title' />
                                            <Skeleton variant='text' />
                                            <Skeleton variant='text' />
                                            <Skeleton variant='text' />
                                        </div>
                                        <div>
                                            <Skeleton variant='title' />
                                            <Skeleton variant='text' />
                                            <Skeleton variant='text' />
                                            <Skeleton variant='text' />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </Col>
                    <Col>
                        {fileInfo?.history?.map((transmittal, i) => (
                            <Transmittal transmittal={transmittal} />
                        ))}
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer className='d-flex'>
                {/* <Button variant='secondary' className='me-auto' ghost onClick={onHide}>
                    Cancel
                </Button> */}
                {canEdit && (
                    <>
                        <Button
                            variant='danger'
                            loading={rejectOlsApi.isLoading}
                            icon='fas fa-times'
                            onClick={() =>
                                rejectOls({
                                    versionId: fileInfo?.incoming_changes?.version?.id,
                                })
                            }
                        >
                            Reject All
                        </Button>
                        <Link
                            href={{
                                pathname: '/nml/tracker/file/history/diff',
                                query: { project_id: fileInfo?.project_id, mode: 'merge' },
                            }}
                            passHref
                        >
                            <a className='btn btn-white ms-auto me-auto' onClick={onHide}>
                                <span className='fal fa-tasks me-2' />
                                Custom
                            </a>
                        </Link>
                        <Button
                            variant='success'
                            icon='fas fa-check'
                            onClick={() =>
                                acceptOls({
                                    versionId: fileInfo?.incoming_changes?.version?.id,
                                })
                            }
                            loading={acceptOlsApi.isLoading}
                        >
                            Accept All
                        </Button>
                    </>
                )}
                {(!canEdit || preview) && (
                    <Alert variant='info'>
                        You cannot accept these changes because you do not currently have the lock
                        for this file
                    </Alert>
                )}
            </Modal.Footer>
        </Modal>
    )
}

function Transmittal({ transmittal }) {
    const [open, setOpen] = useState(false)

    return (
        <Card>
            <Card.Header onClick={() => setOpen(!open)} className='cursor-pointer'>
                {formatDate(transmittal.created)} - {transmittal.subject}
            </Card.Header>
            <Collapse startOpen={false} in={open}>
                <div>
                    <Card.Body>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: transmittal.request
                                    .replaceAll('\r', '<br />')
                                    .replaceAll('\n', '<br />'),
                            }}
                        />
                    </Card.Body>
                </div>
            </Collapse>
        </Card>
    )
}
