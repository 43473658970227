import classnames from 'classnames'
import { uniqueId } from 'lodash-es'
import React, { useContext, useEffect, useRef, useState } from 'react'
import {
    Form as BsForm,
    InputGroup as BsInputGroup,
    Overlay,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap'
import Flatpickr from 'react-flatpickr'
import { AdvancedSelect } from './AdvancedSelect'
import FrontButton from './Button'
import { useModalState } from 'hooks'
export const GlobalFormContext = React.createContext({
    disableAll: false,
})

export function GlobalFormOptions({ children, disableAll = false }) {
    return (
        <GlobalFormContext.Provider
            value={{
                disableAll,
            }}
        >
            {children}
        </GlobalFormContext.Provider>
    )
}

export default function Form({ className, style, children, ...bsProps }) {
    return (
        <BsForm {...bsProps} className={classnames(className, {})} style={style}>
            {children}
        </BsForm>
    )
}

const FormButton = React.forwardRef(
    ({ disable, children, ignoreGlobalDisable, ...bsProps }, ref) => {
        const { disableAll } = useContext(GlobalFormContext)

        return (
            <FrontButton
                {...bsProps}
                ref={ref}
                disabled={disable || (disableAll && !ignoreGlobalDisable)}
            >
                {children}
            </FrontButton>
        )
    }
)

const FormControl = React.forwardRef(
    (
        {
            className,
            name,
            value,
            onChange = () => {},
            light,
            hoverLight,
            borderless,
            plaintext,
            ignoreGlobalDisable,
            disable,
            title,
            onBlurTriggersOnChange,
            onBlur = () => {},
            ...bsProps
        },
        ref
    ) => {
        const { disableAll } = useContext(GlobalFormContext)
        const [formValue, setFormValue] = useState(value)
        const [showMaxLengthTooltip, setShowMaxLengthTooltip] = useState(false)
        const timeoutRef = useRef(null)

        useEffect(() => {
            setFormValue(value)
        }, [value])

        useEffect(() => {
            return () => {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current)
                }
            }
        }, [])

        const handleMaxLengthReached = () => {
            setShowMaxLengthTooltip(true)
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
            timeoutRef.current = setTimeout(() => {
                setShowMaxLengthTooltip(false)
            }, 2000)
        }

        const handleChange = (e) => {
            const newValue = e.target.value
            if (bsProps?.maxLength) {
                if (newValue.length >= bsProps.maxLength) {
                    handleMaxLengthReached()
                }
                const truncatedValue = newValue.slice(0, bsProps.maxLength)
                setFormValue(truncatedValue)
                if (!onBlurTriggersOnChange) {
                    onChange({ target: { name, value: truncatedValue } })
                }
            } else {
                setFormValue(newValue)
                if (!onBlurTriggersOnChange) {
                    onChange(e)
                }
            }
        }


        const BsControl = (
            <BsForm.Control
                {...bsProps}
                onBlur={(e) => {
                    onBlur(e)
                    if (onBlurTriggersOnChange && formValue !== value) {
                        onChange({ target: { name, value: formValue } })
                    }
                }}
                onChange={handleChange}
                value={formValue}
                ref={ref}
                name={name}
                className={classnames(className, {
                    'form-control-light': light,
                    'form-control-borderless': borderless,
                    'form-control-hover-light': hoverLight,
                    'form-control-title': title,
                    'form-control-plaintext': plaintext,
                    'border border-danger': showMaxLengthTooltip,
                })}
                disabled={disable || (disableAll && !ignoreGlobalDisable)}
                onKeyDown={(e) => {
                    if (
                        bsProps?.maxLength &&
                        formValue?.length >= bsProps?.maxLength &&
                        e.key !== 'Backspace' &&
                        e.key !== 'Delete' &&
                        !e.ctrlKey &&
                        !e.metaKey
                    ) {
                        handleMaxLengthReached()
                        e.preventDefault()
                    }
                }}
            />
        )

        if (bsProps?.maxLength) {
            return (
                <OverlayTrigger
                    show={showMaxLengthTooltip}
                    placement='top'
                    overlay={
                        <div
                            id={`overlay-tooltip-${name}`}
                            className='bg-danger rounded p-2 text-white'
                        >
                            Max length reached ({bsProps?.maxLength})
                        </div>
                    }
                >
                    {BsControl}
                </OverlayTrigger>
            )
        } else {
            return BsControl
        }
    }
)

function FormSelect({
    className,
    light,
    hoverLight,
    borderless,
    plaintext,
    ignoreGlobalDisable,
    disable,
    ...bsProps
}) {
    const { disableAll } = useContext(GlobalFormContext)

    return (
        <BsForm.Select
            {...bsProps}
            className={classnames(className, {
                'form-control-light': light,
                'form-control-borderless': borderless,
                'form-control-hover-light': hoverLight,
            })}
            disabled={disable || (disableAll && !ignoreGlobalDisable)}
        />
    )
}

const FormCheck = React.forwardRef(
    ({ ignoreGlobalDisable, className, disable, id, size = 'md', ...bsProps }, ref) => {
        const { disableAll } = useContext(GlobalFormContext)
        if (!id) {
            id = uniqueId('formCheck')
        }

        return (
            <BsForm.Check
                {...bsProps}
                ref={ref}
                id={id}
                disabled={disable || (disableAll && !ignoreGlobalDisable)}
                className={classnames(className, {
                    'form-switch-sm': size == 'sm',
                    'form-switch-xs': size == 'xs',
                })}
            />
        )
    }
)

function FormInputGroup({ className, inputGroupMerge, ...bsProps }) {
    return (
        <BsInputGroup
            {...bsProps}
            className={classnames(className, {
                'input-group-merge': inputGroupMerge,
            })}
        />
    )
}

const FormInputGroupText = React.forwardRef(({ prepend, append, className, ...bsProps }, ref) => {
    return (
        <BsInputGroup.Text
            {...bsProps}
            ref={ref}
            className={classnames(className, {
                'input-group-prepend': prepend,
                'input-group-append': append,
            })}
        />
    )
})

function FormDate({
    value,
    onChange,
    config = {},
    className,
    id = Math.random(),
    hideInput,
    disabled,
    light,
    hoverLight,
    borderless,
    plaintext,
    ignoreGlobalDisable,
    title,

    inline,
    disableWeekends,
    disablePastDates,
    disableToday,
    calendarIcon = true,
    calendarIconPosition = 'end',
    placeholder = '    /       /',
    style,
    ...flatpickrProps
}) {
    const { disableAll } = useContext(GlobalFormContext)

    const parentRef = useRef(null)
    parent = parentRef.current

    const fpRef = useRef(null)

    // useEffect(() => {
    //     HSCore.components.HSMask.init('.js-input-mask')
    // }, [])

    // useEffect(() => {
    //     if (typeof window !== 'undefined') {
    //         const thing = new ShortcutButtonsPlugin({
    //             button: [
    //                 {
    //                     label: 'Yesterday',
    //                 },
    //                 {
    //                     label: 'Today',
    //                 },
    //                 {
    //                     label: 'Tomorrow',
    //                 },
    //             ],
    //             onClick: (index, fp) => {
    //                 let date
    //                 switch (index) {
    //                     case 0:
    //                         date = new Date(Date.now() - 24 * 60 * 60 * 1000)
    //                         break
    //                     case 1:
    //                         date = new Date()
    //                         break
    //                     case 2:
    //                         date = new Date(Date.now() + 24 * 60 * 60 * 1000)
    //                         break
    //                 }
    //                 fp.setDate(date)
    //             },
    //         })
    //         console.log('Thing', thing)
    //     }
    // }, [])

    let fpConfig = {
        allowInput: !disabled,
        dateFormat: 'm/d/Y',
        position: 'auto left',
        static: true,
        inline: inline,
        disable: config?.disable || [],
        minDate: disablePastDates ? 'today' : null,
        plugins: [],
        ...config,
    }

    if (disableToday) {
        fpConfig.disable.push(new Date())
    }
    if (disableWeekends) {
        fpConfig.disable.push((date) => date.getDay() === 0 || date.getDay() === 6)
    }

    // TODO: Fix onBlur functionality
    useEffect(() => {
        const handleBlur = (e) => {
            if (e.target.value !== value) fpRef.current.flatpickr.setDate(e.target.value, true)
        }

        const element = fpRef.current.node

        element.addEventListener('blur', handleBlur)

        return () => element.removeEventListener('blur', handleBlur)
    }, [])

    const isDisabled = disabled || (disableAll && !ignoreGlobalDisable)

    return (
        <span ref={parentRef} id={id} className='position-relative'>
            <Flatpickr
                {...flatpickrProps}
                ref={fpRef}
                value={value}
                options={fpConfig}
                onChange={onChange}
                // onClose={onChange}
                placeholder={placeholder}
                className={classnames('form-control', 'js-input-mask', className, {
                    'flatpickr-hide-input': hideInput,
                    'form-control-light': light,
                    'form-control-borderless': borderless,
                    'form-control-hover-light': hoverLight,
                    'form-control-title': title,
                    'form-control-plaintext': plaintext,
                })}
                onFocus={(e) => e.target.select()}
                disabled={isDisabled}
                style={{
                    ...style,
                    paddingLeft: calendarIcon && calendarIconPosition == 'start' ? '1.75rem' : null,
                    paddingRight: calendarIcon && calendarIconPosition == 'end' ? '1.75rem' : null,
                }}
            />
            {calendarIcon && !inline && (
                <div
                    className='position-absolute text-muted-2 h-100 d-flex align-items-center'
                    style={{
                        left: calendarIconPosition == 'start' ? 11 : null,
                        right: calendarIconPosition == 'end' ? 11 : null,
                        top: 0,
                    }}
                    onClick={() => (!isDisabled ? fpRef.current.flatpickr.open() : null)}
                >
                    <i className='fal fa-calendar fa-xs text-muted-2 d-inline-block align-middle' />
                </div>
            )}
        </span>
    )
}

function FormSwitch({ disable, ignoreGlobalDisable, ...bsProps }) {
    const { disableAll } = useContext(GlobalFormContext)

    return <BsForm.Switch {...bsProps} disabled={disable || (disableAll && !ignoreGlobalDisable)} />
}

Form.Label = BsForm.Label
Form.Control = FormControl
Form.Select = FormSelect
Form.Check = FormCheck
Form.Range = BsForm.Range
Form.Text = BsForm.Text
Form.Group = BsForm.Group
Form.InputGroup = FormInputGroup
Form.InputGroup.Text = FormInputGroupText
Form.Date = FormDate
Form.Switch = FormSwitch
Form.AdvancedSelect = AdvancedSelect
Form.Button = FormButton
Form.Control.Feedback = BsForm.Control.Feedback
