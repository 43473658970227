import { formatDate, isEmpty } from 'components/utils'
import dayjs from 'dayjs'
import { Badge, Button, Card, CollapsingHeaderContext, Dropdown, DropdownButton, Form } from 'front'
import { useContext, useEffect, useState } from 'react'
import { Alert, Col, Modal, Row } from 'react-bootstrap'
import AddEditNoteModal from 'tracker/Utils/Notes/AddEditNoteModal'
import NoteCard from 'tracker/Utils/Notes/NoteCard'
import NoteOverlay from 'tracker/Utils/Notes/NoteOverlay'
import NcsIcon from '../../../../../utils/NcsIcon'
import { ProjectContext } from '../../../Context/ProjectContext'
import { useTrackerDropdowns, useUpdateTask } from '../../../api'
import AttorneySection from './AttorneySection'
import Fees from './Fees'
import { PayablesCard } from './PayablesCard'
import ServiceHistory from './ServiceHistory'
import { TaskStatusBadge } from './Tasks'
import Workflow from './Workflow'
import { sortBy } from 'lodash'
import { useModalState, useTheme } from 'hooks'
import { PortalContext } from '@/components/GlobalState'
import PacketCard from './PacketCard'
import classnames from 'classnames'
import WorkflowHistory from './WorkflowHistory'
import EditDatesModal from './EditDatesModal'

export default function TaskCard({ task }) {
    const { canEdit, fileInfo, projectId } = useContext(ProjectContext)
    const { stickyHeight } = useContext(CollapsingHeaderContext)
    const { isAdmin } = useContext(PortalContext)

    const { mutate: updateTask, ...updateTaskApi } = useUpdateTask({})

    const updateTaskData = (update) => {
        updateTask({
            taskId: task?.id,
            data: {
                task_code: task?.task_code,
                service_level: task?.service_level,
                deadline_ids: task?._deadline_ids,
                ...update,
            },
        })
    }

    let completedDate = dayjs(task?.completed_date).format('M/D/YYYY')
    let createdDate = dayjs(task?.created_date).format('M/D/YYYY')

    const deadlines = fileInfo.deadlines && Object.values(fileInfo.deadlines)
    const deadlineValues =
        task?._deadline_ids?.map((link) => deadlines?.find((d) => d?._id == link)) ?? []
    const deadlineOptions = (deadlines, isOptionsList) => {
        return deadlines?.map((deadline) => {
            let label =
                deadline?.custom_label ??
                fileInfo?.deadline_types?.[deadline?.deadline_type_id]?.label

            if (fileInfo?.project?._is_table_state && deadline?.furnishing_date) {
                const furnishingDateSuffix = formatDate(deadline?.furnishing_date, 'MMMM YYYY')
                label = label + `- ${furnishingDateSuffix}`
            }

            return {
                value: deadline?._id,
                label: (
                    <span
                        className={classnames({
                            'text-danger fw-bold': deadline?.deadline_status == 'NA',
                        })}
                    >
                        {label}
                    </span>
                ),
                isDisabled: deadline?.deadline_status == 'NA',
            }
        })
    }

    const nonNADeadlines = deadlines?.filter((d) => d?.deadline_status !== 'NA')
    const naDeadlineSelected = deadlineValues?.filter(
        (deadline) => deadline?.deadline_status == 'NA'
    )
    const editDatesModal = useModalState(false)
    const showPacketCard = task?.is_packet_enabled
    const allDeadlinesNA = !isEmpty(fileInfo?.deadlines) && nonNADeadlines?.length === 0
    const last = task?.stages[task.stages.length - 1]?.stage === task?.current_stage
    const completed = last && task?.status === 'COMPLETED'

    return (
        <div className='collapsing-header-sticky' style={{ top: `${stickyHeight + 28}px` }}>
            <Card className=''>
                <Card.Header className='d-flex justify-content-between align-items-center'>
                    <div>
                        <Card.Title as='h4'>
                            {task?._task_code_label}{' '}
                            <TaskStatusBadge status={task?.status} className='ms-2' />
                            {task?.is_priority == 1 && (
                                <TaskStatusBadge status={'PRIORITY'} className='ms-2' />
                            )}
                            {updateTaskApi.isLoading && (
                                <Badge
                                    bg='secondary'
                                    soft
                                    className='ms-2'
                                    icon='far fa-spinner-third fa-spin'
                                >
                                    Saving
                                </Badge>
                            )}
                        </Card.Title>
                        <Card.Subtitle className='d-flex justify-content-between align-items-center pe-2 small'>
                            <span className='d-block'>
                                Created by <b>{task?._created_contact_name ?? 'Unknown'}</b>
                                {task?.created_date && (
                                    <span className=''>
                                        {' '}
                                        on <b>{createdDate}</b>
                                    </span>
                                )}
                            </span>
                            {task?.completed_date && (
                                <>
                                    <i className='d-block fal fa-arrow-right mx-2' />
                                    <span className='d-block'>Completed on {completedDate}</span>
                                </>
                            )}
                        </Card.Subtitle>
                    </div>
                    <div>
                        <TaskDropdownMenu
                            task={task}
                            updateTask={updateTask}
                            isAdmin={isAdmin}
                            updateTaskApi={updateTaskApi}
                        />
                    </div>
                </Card.Header>
                <Card.Body className='pb-1'>
                    {updateTaskApi.isError && (
                        <Alert variant='soft-danger' className='d-flex align-items-center'>
                            <i className='fas fa-exclamation-triangle fa-lg ms-1 me-3 d-block' />
                            <div>
                                <div className='fw-bold'>Error saving task</div>
                                {updateTaskApi?.error?.response?.data?.errors?.[0]?.message && (
                                    <div className='fst-italic'>
                                        {updateTaskApi?.error?.response?.data?.errors?.[0]?.message}
                                    </div>
                                )}
                            </div>
                        </Alert>
                    )}
                    <TaskSelectionMenu
                        selectedTaskCode={task?.task_code}
                        selectedServiceLevelCode={task?.service_level}
                        setSelectedTaskCode={(taskCode) => {
                            updateTaskData({
                                task_code: taskCode,
                                deadline_ids: [],
                            })
                        }}
                        setSelectedServiceLevelCode={(serviceLevelCode) => {
                            updateTaskData({
                                service_level: serviceLevelCode,
                            })
                        }}
                        completed={completed}
                    />

                    {task?.task_code !== 'MON' && task?.task_code !== 'NMON' && (
                        <div className='mb-4'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <Form.Label>Linked Date(s)</Form.Label>
                                {canEdit && deadlineValues?.length > 0 && (
                                    <a className='link p-0' onClick={editDatesModal.show}>
                                        Edit Date{deadlineValues?.length > 1 ? 's' : ''}
                                    </a>
                                )}
                            </div>
                            {!isEmpty(fileInfo?.deadlines) && (
                                <Form.AdvancedSelect
                                    isMulti
                                    onChange={(deadlines) => {
                                        updateTaskData({
                                            deadline_ids: deadlines?.map((d) => d?.value),
                                        })
                                    }}
                                    value={deadlineOptions(deadlineValues)}
                                    options={deadlineOptions(sortBy(nonNADeadlines, ['_datekey']))}
                                    placeholder={
                                        allDeadlinesNA ? (
                                            <small className='text-danger fw-bold fst-italic'>
                                                <i className='fas fa-exclamation-circle me-2' />
                                                All deadlines are {`N/A`}
                                            </small>
                                        ) : (
                                            'Select date(s) to link to'
                                        )
                                    }
                                    isDisabled={
                                        (allDeadlinesNA && !(deadlineValues?.length > 0)) ||
                                        completed
                                    }
                                    styleOverrides={{
                                        control: () => {
                                            if (naDeadlineSelected?.length > 0)
                                                return { borderColor: 'rgb(var(--bs-danger-rgb))' }
                                        },
                                    }}
                                />
                            )}
                            {naDeadlineSelected?.length > 0 && (
                                <small className='text-danger fw-bold d-block text-center mt-1'>
                                    <i className='fas fa-exclamation-circle me-2' />
                                    Please remove{' '}
                                    {naDeadlineSelected?.length > 1
                                        ? 'all N/A deadlines'
                                        : 'the N/A deadline'}{' '}
                                    selected with this task
                                </small>
                            )}
                        </div>
                    )}
                    <Workflow task={task} />
                </Card.Body>

                {/* Remove this div when adding WorkflowHistory back */}
                <div className='mb-3' />
                {/* <WorkflowHistory task={task} /> */}
            </Card>
            {task?.service_level === 'ATTORNEY' && (
                <AttorneySection
                    taskId={task?.id}
                    attorneyId={task?.attorney_id}
                    firmNo={task?.firm_no}
                    state={fileInfo?.project?.state}
                />
            )}
            {(showPacketCard || task?.packets?.length > 0) && <PacketCard task={task} />}
            <Fees task={task} canEdit={canEdit} updateTask={updateTask} />
            <NoteCard task={task} />
            <ServiceHistory service={task} />
            <PayablesCard task={task} />
            <EditDatesModal
                show={editDatesModal.isOpen}
                onHide={editDatesModal.hide}
                deadlines={deadlineValues}
            />
        </div>
    )
}

export function TaskSelectionMenu({
    selectedTaskCode,
    selectedServiceLevelCode,
    setSelectedServiceLevelCode,
    setSelectedTaskCode,
    completed = false,
}) {
    const { data: dropdowns } = useTrackerDropdowns()
    const allTasks = dropdowns?.['task.code']
    const taskCategories = dropdowns?.['task.category']
    const serviceLevels = dropdowns?.['task.service_level']

    const groupedOptions = taskCategories
        ?.filter(({ is_active }) => is_active)
        ?.map(({ task_category }) => ({
            label: (
                <span>
                    <NcsIcon
                        dept='nml'
                        size='sm'
                        variant='far'
                        category='taskCategory'
                        value={task_category}
                        className='me-2 fa-fw'
                    />
                    {task_category}
                </span>
            ),
            options: allTasks
                ?.filter((task) => task?.task_category == task_category)
                ?.map(taskOption),
        }))

    const selectedTask = allTasks?.find((task) => task?.code == selectedTaskCode)

    const selectedServiceLevel = serviceLevels?.find(
        (serviceLevel) => serviceLevel?.code == selectedServiceLevelCode
    )

    return (
        <Row className={'mb-4'}>
            <Col>
                <Form.Label>Service Code</Form.Label>
                <Form.AdvancedSelect
                    value={taskOption(selectedTask)}
                    options={groupedOptions}
                    onChange={({ taskCode }) => setSelectedTaskCode(taskCode)}
                    isClearable={false}
                    isDisabled={completed}
                />
            </Col>
            <Col>
                <Form.Label>Service Level</Form.Label>
                <Form.AdvancedSelect
                    isSearchable={false}
                    value={serviceLevelOption(selectedServiceLevel)}
                    options={serviceLevels?.sort((a, b) => a.id - b.id).map(serviceLevelOption)}
                    onChange={(serviceLevel) => setSelectedServiceLevelCode(serviceLevel?.code)}
                    isClearable={false}
                    isDisabled={completed}
                />
            </Col>
        </Row>
    )
}

const taskOption = (task) => ({
    label: (
        <div className='d-flex justify-content-start'>
            <div>
                <Badge className='me-2' bg='dark' soft style={{ minWidth: '47px' }}>
                    {task?.code}
                </Badge>
            </div>
            <small className='d-block' style={{ paddingTop: '0.2em' }}>
                {task?.label}
            </small>
        </div>
    ),
    value: `${task?.code} - ${task?.label}`,
    taskCode: task?.code,
})

const serviceLevelOption = (serviceLevel) => ({
    label: (
        <span>
            <NcsIcon
                dept='nml'
                size='sm'
                variant='far'
                category='serviceLevel'
                value={serviceLevel?.code}
                className='me-3 fa-fw'
            />
            {serviceLevel?.label}
        </span>
    ),
    value: serviceLevel?.label,
    code: serviceLevel?.code,
})

function TaskDropdownMenu({ task, updateTask, isAdmin = false, updateTaskApi }) {
    const { canEdit } = useContext(ProjectContext)
    const [addTaskNoteModal, setAddTaskNoteModal] = useState(false)
    const cancelTaskModal = useModalState(false)
    const deleteTaskModal = useModalState(false)
    const [loading, setLoading] = useState(false)
    const cancelTask = task?.status !== 'CANCELLED'
    const { is_priority } = task
    const isPriority = is_priority ?? 0

    return (
        <>
            <DropdownButton
                ghost
                variant='secondary'
                size='sm'
                noToggleArrow
                circle
                className='d-inline-block'
                icon={'fas fa-ellipsis-v'}
            >
                <Dropdown.Item
                    onClick={() => {
                        setAddTaskNoteModal(true)
                    }}
                >
                    Add Task Note
                </Dropdown.Item>
                <AddEditNoteModal
                    show={addTaskNoteModal}
                    task={task}
                    cancel={() => {
                        setAddTaskNoteModal(false)
                    }}
                />
                <NoteOverlay dropdownItemBtn task={task} />
                <Dropdown.Item
                    disabled={!canEdit}
                    onClick={() => {
                        updateTask({
                            taskId: task?.id,
                            data: { is_priority: isPriority == 0 ? 1 : 0 },
                        })
                    }}
                >
                    {isPriority == 0 ? 'Mark as' : 'Remove '} Priority
                </Dropdown.Item>
                <Dropdown.Item
                    disabled={!canEdit}
                    className={task?.status === 'CANCELLED' || !canEdit ? '' : 'text-danger'}
                    onClick={cancelTaskModal.show}
                >
                    {task?.status === 'CANCELLED' ? 'Reopen' : 'Cancel'}
                </Dropdown.Item>
                {isAdmin && (
                    <Dropdown.Item
                        className={!canEdit ? '' : 'text-danger'}
                        disabled={!canEdit}
                        onClick={deleteTaskModal.show}
                    >
                        Delete
                    </Dropdown.Item>
                )}
            </DropdownButton>
            <Modal
                show={deleteTaskModal.isOpen}
                onHide={() => {
                    deleteTaskModal.hide()
                    updateTaskApi.reset()
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>Would you like to delete {task?._task_code_label}?</div>
                    {updateTaskApi.isError && (
                        <Alert variant='soft-danger'>Error deleting task</Alert>
                    )}
                </Modal.Body>
                <Modal.Footer className='flex justify-content-between'>
                    <Button
                        variant='ghost'
                        onClick={() => {
                            deleteTaskModal.hide()
                            updateTaskApi.reset()
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        variant={'danger'}
                        loading={loading}
                        disabled={loading}
                        onClick={async () => {
                            setLoading(true)
                            updateTask(
                                {
                                    taskId: task?.id,
                                    data: {
                                        status: 'DELETED',
                                    },
                                },
                                {
                                    onSuccess: () => {
                                        console.log('task deleted successfully')
                                        setLoading(false)
                                        deleteTaskModal.hide()
                                    },
                                    onError: () => {
                                        setLoading(false)
                                    },
                                }
                            )
                        }}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={cancelTaskModal.isOpen}
                onHide={() => {
                    cancelTaskModal.hide()
                    updateTaskApi.reset()
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{cancelTask ? 'Cancel' : 'Reopen'} Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        Would you like to {cancelTask ? 'cancel' : 'reopen'}{' '}
                        {task?._task_code_label}?
                    </div>
                    {updateTaskApi.isError && (
                        <Alert variant='soft-danger'>
                            <div className='fw-bold'>
                                Error while trying to {cancelTask ? 'cancel' : 'reopen'} task
                            </div>
                            {updateTaskApi?.error && (
                                <div>
                                    {updateTaskApi?.error?.response?.data?.errors?.[0]?.message}
                                </div>
                            )}
                        </Alert>
                    )}
                </Modal.Body>
                <Modal.Footer className='flex justify-content-between'>
                    <Button
                        variant='ghost'
                        onClick={() => {
                            cancelTaskModal.hide()
                            updateTaskApi.reset()
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        variant={cancelTask ? 'danger' : 'primary'}
                        loading={loading}
                        disabled={loading}
                        onClick={async () => {
                            setLoading(true)
                            updateTask(
                                {
                                    taskId: task?.id,
                                    data: {
                                        status: cancelTask ? 'CANCELLED' : 'IN PROGRESS',
                                    },
                                },
                                {
                                    onSuccess: () => {
                                        console.log('task cancelled successfully')
                                        setLoading(false)
                                        cancelTaskModal.hide()
                                    },
                                    onError: () => {
                                        setLoading(false)
                                    },
                                }
                            )
                        }}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
