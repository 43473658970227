import React, { useState } from 'react'

export const LayoutContext = React.createContext({})

export default function FrontLayout({ children }) {
    const [leftSidebarCollapsed, setLeftSidebarCollapsed] = useState(false)

    return (
        <LayoutContext.Provider value={{ leftSidebarCollapsed, setLeftSidebarCollapsed }}>
            <div
                className={`has-navbar-vertical-aside navbar-vertical-aside-show-xl ${
                    leftSidebarCollapsed ? 'navbar-vertical-aside-mini-mode' : ''
                }`}
            >
                <main id='content' role='main' className='main'>
                    {children}
                </main>
            </div>
        </LayoutContext.Provider>
    )
}
