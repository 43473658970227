import { formatDate } from 'components/utils'
import dayjs from '@/lib/dayjs'
import { Card, Form } from 'front'
import { useState } from 'react'
import { ButtonGroup, ToggleButton } from 'react-bootstrap'
import { useTrackerDropdowns } from 'tracker/api'

export function PrintDate({ value, defaultPrintDate, onChange, canEdit = true }) {
    const [printTime, setPrintTime] = useState('morning')
    const { data: dropdowns } = useTrackerDropdowns()

    return (
        <Card className='pb-2 w-100'>
            <Card.Header className='d-flex justify-content-between align-items-center pe-3'>
                <Card.Title as='h5' className='d-block'>
                    Print Date
                </Card.Title>
            </Card.Header>
            <Card.Body className='pt-4 pb-0'>
                {canEdit && (
                    <QuickDateActions
                        value={value}
                        onChange={onChange}
                        defaultPrintDate={defaultPrintDate}
                    />
                )}
            </Card.Body>
            <div className='d-flex justify-content-center position-relative'>
                {!canEdit && (
                    <div
                        className='position-absolute top-0 start-0 end-0 bottom-0 bg-white'
                        style={{ zIndex: 99, opacity: 0.5 }}
                    />
                )}
                <Form.Date
                    disabled={!canEdit}
                    inline
                    value={formatDate(value)}
                    onChange={([date]) => onChange(date)}
                    hideInput
                    disablePastDates={canEdit}
                    config={{
                        disable: dropdowns?.holidays?.map((date) => formatDate(date)),
                    }}
                    disableWeekends
                />
            </div>
            {canEdit && (
                <Card.Body className='pt-0 pb-2'>
                    <ButtonGroup className='w-100 pt-0 pb-2'>
                        {quickTimes.map((quickTime, i) => {
                            const checked = quickTime.value === printTime
                            return (
                                <ToggleButton
                                    key={i}
                                    id={`time-${i}`}
                                    type='radio'
                                    size='xs'
                                    variant={`outline-${checked ? 'primary' : 'secondary'}`}
                                    name={quickTime.value}
                                    value={quickTime.value}
                                    checked={checked}
                                    onClick={() => {
                                        setPrintTime(quickTime.value)
                                    }}
                                    disabled={!canEdit}
                                >
                                    {quickTime.label}
                                </ToggleButton>
                            )
                        })}
                    </ButtonGroup>
                </Card.Body>
            )}
        </Card>
    )
}

const quickTimes = [
    {
        value: 'morning',
        label: 'MORNING',
    },
    {
        value: 'noon',
        label: 'NOON',
    },
]

const tomorrow = dayjs().add(1, 'day')
const currentMonth = dayjs().month()
const monthOffsetFor15th = dayjs().date() < 15 ? 0 : 1
const MONTH_OF_NEXT_15TH = currentMonth + monthOffsetFor15th

function QuickDateActions({ value, onChange, defaultPrintDate }) {
    const { data: dropdowns } = useTrackerDropdowns()

    const desiredDateToAvailableDate = (date) => {
        date = dayjs(date)

        let availableDateSeekStrategy = 'before'
        while (
            date.day() === 0 ||
            date.day() === 6 ||
            dropdowns?.holidays?.includes(date.format('YYYY-MM-DD'))
        ) {
            if (date.isSameOrBefore(tomorrow)) {
                availableDateSeekStrategy = 'after'
            }
            if (availableDateSeekStrategy === 'before') {
                date = date.subtract(1, 'day')
            } else if (availableDateSeekStrategy === 'after') {
                date = date.add(1, 'day')
            }
        }
        return date.format('MM/DD/YYYY')
    }

    const quickDates = [
        {
            name: 'default',
            label: 'DEFAULT',
            value: dayjs(defaultPrintDate).format('MM/DD/YYYY'),
        },
        {
            name: '15th',
            label: null,
            value: desiredDateToAvailableDate(
                dayjs().date(15).month(MONTH_OF_NEXT_15TH).format('MM/DD/YYYY')
            ),
        },
        // {
        //     name: 'client',
        //     label: 'CLIENT PREF',
        //     value: today,
        // },
    ]

    return (
        <ButtonGroup className='w-100'>
            {quickDates.map((quickDate, i) => {
                const checked = quickDate.value == formatDate(value)
                const label = quickDate.label ?? getDateAbbreviation(quickDate.value)
                return (
                    <ToggleButton
                        key={i}
                        id={`quick-date-${i}`}
                        type='radio'
                        size='xs'
                        variant={`outline-${checked ? 'primary' : 'secondary'}`}
                        name={quickDate.name}
                        value={quickDate.value}
                        checked={checked}
                        onClick={() => {
                            onChange(quickDate.value)
                        }}
                    >
                        {label}
                    </ToggleButton>
                )
            })}
        </ButtonGroup>
    )
}

// Returns like JAN 15th
function getDateAbbreviation(date) {
    const month = dayjs(date).format('MMM')
    const day = dayjs(date).format('D')
    const ordinal = getDayOfMonthOrdinal(day)

    return (
        <span>
            {month.toUpperCase()} {day}
            <sup>{ordinal}</sup>
        </span>
    )
}

function getDayOfMonthOrdinal(dayOfMonth) {
    if (dayOfMonth > 3 && dayOfMonth < 21) return 'th'
    switch (dayOfMonth % 10) {
        case 1:
            return 'st'
        case 2:
            return 'nd'
        case 3:
            return 'rd'
        default:
            return 'th'
    }
}
