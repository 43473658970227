import classnames from 'classnames'
import { Badge, Card, Form, Table } from 'front'
import { useContext, useState } from 'react'
import { useResetFees, useTrackerDropdowns } from 'tracker/api'
import { ProjectContext } from '../../../Context/ProjectContext'
import AddFeeModal from './AddFeeModal'
import EditFeeModal from './EditFeeModal'
import { Tooltip } from '@/components/utils'

export default function Fees({ task, updateTask }) {
    const { projectId, canEdit } = useContext(ProjectContext)
    const { mutate: resetFees, ...resetFeesApi } = useResetFees({ projectId, taskId: task?.id })
    const [showAddFeeModal, setShowAddFeeModal] = useState(false)

    if (task?.fees?.length == 0) return null

    let feesTotal = 0
    let showResetFeesButton = false
    task?.fees?.forEach(({ amount, is_overridden, is_deleted, fee_type }) => {
        if (!is_deleted) feesTotal += parseFloat(amount)

        let isDeletedFee = is_deleted && fee_type !== 'CHARGES'
        let isOverriddenFee = is_overridden && fee_type !== 'CHARGES'
        let isAddedCharge = !is_deleted && fee_type === 'CHARGES'

        if (isDeletedFee || isOverriddenFee || isAddedCharge) showResetFeesButton = true
    })
    feesTotal = feesTotal.toFixed(2)

    return (
        <Card>
            <Card.Header className={'d-flex justify-content-between align-items-center'}>
                <Card.Title>
                    Fees
                    {resetFeesApi?.isLoading && (
                        <span className={'far fa-spinner-third fa-spin me-2'} />
                    )}
                </Card.Title>
                {canEdit && (
                    <div>
                        <a
                            className='link p-0'
                            onClick={() => {
                                setShowAddFeeModal(true)
                            }}
                        >
                            Add Charge
                        </a>
                        {showResetFeesButton && (
                            <a className='link ms-3' onClick={resetFees}>
                                Reset
                            </a>
                        )}
                    </div>
                )}
            </Card.Header>
            <Card.Body>
                <Table className='w-100' borderless>
                    <Table.Body>
                        {task?.fees
                            ?.filter(
                                ({ is_deleted, fee_type }) =>
                                    !(is_deleted && fee_type === 'CHARGES')
                            )
                            ?.map((fee, i) => (
                                <Fee fee={fee} canEdit={canEdit} key={i} />
                            ))}
                        <FeesTotal total={feesTotal} />
                    </Table.Body>
                </Table>
                <AddFeeModal
                    taskId={task?.id}
                    show={showAddFeeModal}
                    onHide={() => {
                        setShowAddFeeModal(false)
                    }}
                />
            </Card.Body>
            <Card.Footer className='d-flex gap-3'>
                <Tooltip content='This task was billed under a different service'>
                    <span>
                        <Form.Check
                            type='switch'
                            label='Billed Separately'
                            name='is_billed_separate'
                            checked={task?.is_billed_separate}
                            onChange={({ target: { checked } }) =>
                                updateTask({
                                    taskId: task?.id,
                                    data: {
                                        is_billed_separate: checked,
                                    },
                                })
                            }
                        />
                    </span>
                </Tooltip>
                <Tooltip content='This task was billed as a priority service'>
                    <span>
                        <Form.Check
                            type='switch'
                            label='Billed Priority'
                            name='is_billed_priority'
                            checked={task?.is_billed_priority}
                            onChange={({ target: { checked } }) =>
                                updateTask({
                                    taskId: task?.id,
                                    data: {
                                        is_billed_priority: checked,
                                    },
                                })
                            }
                        />
                    </span>
                </Tooltip>
            </Card.Footer>
        </Card>
    )
}

function Fee({ fee, canEdit }) {
    const [showEditFeeModal, setShowEditFeeModal] = useState(false)
    const { data: dropdowns } = useTrackerDropdowns()

    const isFeeExported = fee.status == 'EXPORTED' || fee.status == 'FINALIZED'

    return (
        <Table.Row
            className={classnames({
                'text-muted-3': fee.is_deleted,
            })}
        >
            <Table.Td>
                <div className='p-0'>
                    <small
                        className={classnames('fw-bolder smaller', {
                            'text-info': !fee.is_deleted,
                            'text-decoration-line-through': fee.is_deleted,
                        })}
                    >
                        {fee.fee_type}
                    </small>
                </div>
            </Table.Td>
            <Table.Td
                className={classnames({
                    'text-decoration-line-through': fee.is_deleted,
                })}
            >
                {fee.fee_type == 'POSTAGE'
                    ? dropdowns?.['docgen.postage']?.find(
                          ({ code, legacy_value }) =>
                              code == fee.rate_code || legacy_value == fee.rate_code
                      )?.label
                    : fee.description}
            </Table.Td>
            <Table.Td
                className={classnames('text-end', {
                    'text-decoration-line-through': fee.is_deleted,
                })}
            >
                {fee.quantity != 1 && fee.quantity}
            </Table.Td>
            <Table.Td className='text-center'>
                {fee.quantity != 1 && <i className='fal fa-times fa-xs mx-2' />}
            </Table.Td>
            <Table.Td
                className={classnames('text-start', {
                    'text-decoration-line-through': fee.is_deleted,
                })}
            >
                {fee.quantity != 1 && `$${fee.unit_rate}`}
            </Table.Td>
            <Table.Td className='text-center'>
                <i className='fal fa-equals fa-xs mx-2' />
            </Table.Td>
            <Table.Td
                className={classnames('text-start', {
                    'text-decoration-line-through': fee.is_deleted,
                })}
            >
                ${fee.amount}
            </Table.Td>
            <Table.Td className='text-start'>
                <div className='p-0'>
                    {fee.is_deleted && (
                        <Badge soft bg='danger'>
                            Deleted
                        </Badge>
                    )}
                    {fee.is_overridden == 1 && !fee.is_deleted && fee.fee_type != 'CHARGES' && (
                        <Badge soft bg='secondary'>
                            Edited
                        </Badge>
                    )}
                    {fee.is_overridden == 1 && !fee.is_deleted && fee.fee_type == 'CHARGES' && (
                        <Badge soft bg='success'>
                            Added
                        </Badge>
                    )}
                </div>
            </Table.Td>
            <Table.Td className='text-end'>
                {canEdit && !isFeeExported && !fee.is_deleted && (
                    <a
                        className='link'
                        onClick={() => {
                            setShowEditFeeModal(true)
                        }}
                    >
                        Edit
                    </a>
                )}
                {isFeeExported && <Badge bg='success'>Exported</Badge>}
            </Table.Td>
            <EditFeeModal
                show={showEditFeeModal}
                onHide={() => {
                    setShowEditFeeModal(false)
                }}
                fee={fee}
            />
        </Table.Row>
    )
}

const FeesTotal = ({ total }) => (
    <Table.Row className='fw-bolder'>
        <Table.Td colSpan={4} className=''></Table.Td>
        <Table.Td className='text-start'>
            <small className='p-0'>TOTAL</small>
        </Table.Td>
        <Table.Td className='text-center'>
            <i className='fas fa-equals fa-xs mx-2' />
        </Table.Td>
        <Table.Td className='text-start'>${total}</Table.Td>
    </Table.Row>
)
