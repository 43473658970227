import { useMutation, useQueryClient } from '@tanstack/react-query'
import { mergeConfigs } from '../../../../utils'
import axios from '../Context/AxiosSetup'
import { Project } from './project.api'

export class Fees {
    static updateFee = (taskId, feeId, data) => {
        return axios
            .patch(`/nml/tracker/2/task/${taskId}/fee/${feeId}/json`, data)
            .then((response) => response.data)
    }

    static create = (taskId, data) => {
        return axios
            .post(`/nml/tracker/2/task/${taskId}/fee/charges/json`, data)
            .then((response) => response.data)
    }

    static resetFees = (taskId) => {
        return axios
            .post(`/nml/tracker/2/task/${taskId}/recalculate/fees/json`)
            .then((response) => response.data)
    }

    static deleteFee = (taskId, feeId) => {
        return axios
            .delete(`nml/tracker/2/task/${taskId}/fee/${feeId}/json`)
            .then((response) => response.data)
    }
}

export const useUpdateFee = ({ projectId, taskId, feeId }, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        (data) => Fees.updateFee(taskId, feeId, data),
        mergeConfigs(
            {
                onSuccess: (data) => {
                    queryClient.setQueryData(Project.keys.project(projectId), {
                        ...queryClient.getQueryData(Project.keys.project(projectId)),
                        ...data,
                    })
                },
            },
            config
        )
    )
}

export const useCreateFee = ({ projectId, taskId }, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        (data) => Fees.create(taskId, data),
        mergeConfigs(
            {
                onSuccess: (data) => {
                    queryClient.setQueryData(Project.keys.project(projectId), {
                        ...queryClient.getQueryData(Project.keys.project(projectId)),
                        ...data,
                    })
                },
            },
            config
        )
    )
}

export const useResetFees = ({ projectId, taskId }, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        () => Fees.resetFees(taskId),
        mergeConfigs(
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(Project.keys.project(projectId))
                },
            },
            config
        )
    )
}

export const useDeleteFee = ({ projectId, taskId, feeId }, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        () => Fees.deleteFee(taskId, feeId),
        mergeConfigs(
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(Project.keys.project(projectId))
                },
            },
            config
        )
    )
}
