import React, { Fragment, useContext, useState } from 'react'
import EmailModal from '../../email_form/EmailModal'
import LoadingSpinner from '../../LoadingSpinner'
import { DynamicCardContext } from '../Context/DynamicCardContext'
import ActionOption from './ActionOption'

export default function ActionDisplay({ action }) {
    const [loading, setLoading] = useState(false)
    const { apiCall, docuwareOptions } = useContext(DynamicCardContext)
    switch (action.type) {
        case 'api':
            return (
                <button
                    className={'btn ' + (action.btnType ?? 'btn-outline-secondary')}
                    onClick={() => {
                        setLoading(true)
                        apiCall(action, (data) => {
                            setLoading(false)
                            console.log('api call callback', data)
                        })
                    }}
                >
                    {loading ? (
                        <Fragment>
                            <LoadingSpinner /> Loading...
                        </Fragment>
                    ) : (
                        action.label
                    )}
                </button>
            )
        case 'dropdown':
            return (
                <Fragment>
                    <button
                        className={
                            'btn dropdown-toggle ' + (action.btnType ?? 'btn-outline-secondary')
                        }
                        data-bs-toggle='dropdown'
                    >
                        {action.label}
                    </button>
                    <div className={'dropdown-menu'}>
                        {action.list.map((data, i) => (
                            <ActionOption key={i} data={data} />
                        ))}
                    </div>
                </Fragment>
            )
        case 'email':
            return (
                <EmailModal
                    {...docuwareOptions}
                    {...action.info}
                    btnStyle={'btn btn-outline-secondary'}
                >
                    {action.label}
                </EmailModal>
            )
        case 'custom':
            return action.custom(action)
        default:
            return false
    }
}
