import React, { useState, useEffect, useContext } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useRouter } from 'next/router'
import { Context } from '../../nml/tracker/Context/GlobalState'
import isEmpty from '../../utils/isEmpty'

export default function Page({ children }) {
    const { trackerTab } = useContext(Context)
    const router = useRouter()
    const currentTab = trackerTab?.links?.find((t) => router.pathname.startsWith(t.href))
    // console.log('current',currentTab)

    return (
        <div>
            {/*<div className="page-header">*/}
            {/*    <div className="row align-items-center">*/}
            {/*        <div className="col-sm mb-2 mb-sm-0">*/}
            {/*            {!isEmpty(trackerTab) &&*/}
            {/*                <>*/}
            {/*                    <Breadcrumb>*/}
            {/*                        <Breadcrumb.Item href="/nml/tracker/dashboard">Dashboard</Breadcrumb.Item>*/}
            {/*                        <Breadcrumb.Item href={trackerTab.href}>*/}
            {/*                            {trackerTab?.title}*/}
            {/*                        </Breadcrumb.Item>*/}
            {/*                        <Breadcrumb.Item active>{currentTab?.title}</Breadcrumb.Item>*/}
            {/*                    </Breadcrumb>*/}

            {/*                    <h2 className="page-header-title">{currentTab?.title}</h2>*/}
            {/*                </>*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {children}
        </div>
    )
}
