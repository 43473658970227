import { formatDate } from 'components/utils'
import { useEffect, useState } from 'react'
import { usePacket, useTrackerDropdowns, useUpdatePacket } from 'tracker/api'

const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)
let updatePacketTimeout

export const usePacketPreferences = (projectId, packetId, isModalOpen) => {
    // Packet API
    const { data: packet, ...packetApi } = usePacket(packetId, {
        enabled: !!packetId && isModalOpen,
    })
    // Update Packet API
    const { mutate: updatePacket, ...updatePacketApi } = useUpdatePacket(projectId)
    const { data: dropdowns } = useTrackerDropdowns()

    const defaultPrintDate = getNextAvailableDate(tomorrow, dropdowns?.holidays || [])

    const [minMailDate, setMinMailDate] = useState(defaultPrintDate)
    const [options, setOptions] = useState({
        return_address: 'ncs',
        special_handling: null,
        special_handling_message: null,
    })

    useEffect(() => {
        if (packet && packet?.id == packetId) {
            setMinMailDate(packet?.min_mail_date)
            setOptions(packet?.options)
        }
    }, [packet])

    function updateMinMailDate(date) {
        setMinMailDate(date)

        if (packetId && date) {
            updatePacket({
                packetId,
                data: { min_mail_date: formatDate(date, 'YYYY-MM-DD') },
            })
        }
    }

    function updateOptions(optionsUpdate, delay = 300) {
        const payload = { ...options, ...optionsUpdate }
        setOptions(payload)

        if (packetId) {
            // Debounce the updatePacket API call
            clearTimeout(updatePacketTimeout)
            updatePacketTimeout = setTimeout(() => {
                updatePacket({
                    packetId,
                    data: { options: payload },
                })
            }, delay) // Adjust the debounce delay as needed
        }
    }

    return {
        minMailDate,
        updateMinMailDate,
        defaultPrintDate,
        options,
        updateOptions,
        isLoading: updatePacketApi.isLoading,
    }
}

export function getNextAvailableDate(startDate, disabledDates) {
    // Ensure disabledDates is an array of date strings in 'YYYY-MM-DD' format
    const disabledDatesSet = new Set(disabledDates)

    // Create a date object for the start date
    let currentDate = new Date(startDate)

    // Function to check if the date is a weekend
    function isWeekend(date) {
        const dayOfWeek = date.getDay()
        return dayOfWeek === 0 || dayOfWeek === 6 // 0 = Sunday, 6 = Saturday
    }

    // Function to check if the date is disabled
    function isDisabled(date) {
        date.setHours(0, 0, 0, 0)
        const dateString = date.toISOString().split('T')[0] // Convert to 'YYYY-MM-DD'
        return disabledDatesSet.has(dateString)
    }

    // Check next days until an available date is found
    while (isWeekend(currentDate) || isDisabled(currentDate)) {
        currentDate.setDate(currentDate.getDate() + 1) // Move to the next day
    }

    currentDate.setHours(0, 0, 0, 0) // Reset the time to 00:00:00
    // Return the available date as a string in 'YYYY-MM-DD' format
    return currentDate.toISOString().split('T')[0]
}
